import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Container,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { getError, maxitemsPerPage } from "../../utils";
import { Store } from "../../Store";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Pagination from "../../components/Pagination";
import {
  BsPlusCircle,
  BsTrash,
  BsPencilSquare,
  BsFileEarmarkRichtext,
} from "react-icons/bs";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        eventos: action.payload,
        loading: false,
        itemQuantity: action.payload.length,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

function EventList() {
  const [{ loading, error, eventos, successDelete, itemQuantity }, dispatch] =
    useReducer(reducer, {
      eventos: [],
      loading: true,
      error: "",
      itemQuantity: 0,
    });

  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * maxitemsPerPage;
  const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
  let currentPosts = eventos.slice(indexOfFirstPost, indexOfLastPost);
  //change page
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    currentPosts = eventos.slice(indexOfFirstPost, indexOfLastPost);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/events`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      fetchData();
    }
  }, [successDelete, userInfo]);

  //ELIMINAR LOTE
  async function deleteLoteHandler(lote) {
    try {
      dispatch({ type: "DELETE_REQUEST" });
      const { data } = await axios.delete(`/api/lotes/${lote._id}`, {
        headers: { authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: "DELETE_SUCCESS" });
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: "DELETE_FAIL" });
    }
  }

  //ELIMINA TODOS LOS LOTES DE UNA CANCHA
  async function deleteLotesCancha(cancha) {
    try {
      const { data } = await axios.delete(
        `/api/lotes/deleteLoteCancha/${cancha._id}`
      );
      // const lotes = data;
      // lotes.forEach((l) => {
      //   deleteLoteHandler(l);
      // });
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: "DELETE_FAIL" });
    }
  }

  async function deleteCanchaHandler(cancha) {
    if (window.confirm("Seguro desea eliminar la cancha?")) {
      try {
        deleteLotesCancha(cancha);
        dispatch({ type: "DELETE_REQUEST" });
        const { data } = await axios.delete(`/api/events/${cancha._id}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "DELETE_SUCCESS" });
        toast.success("Cancha Eliminada");
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: "DELETE_FAIL" });
      }
    }
  }

  async function editCanchaHandler(cancha) {
    navigate(`/AdminScreen/editEvent/${cancha._id}`);
  }

  async function detailsCanchaHandler(cancha) {
    navigate(`/AdminScreen/event/${cancha._id}`);
  }

  async function newCanchaHandler() {
    navigate("/AdminScreen/newEvent");
  }

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <Helmet>
        <title>Lista de Eventos</title>
      </Helmet>

      <div className="container admin-con">
        <div className="row adminProdBtnRow align-items-center">
          {/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
          {/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
        </div>
        <div>
          <Container fluid className="m-3 mt-5">
            <h1 className="text-center">Lista de Eventos</h1>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <Button
                className="btn btn-dark m-1 fixed-right"
                value="Crear Cancha"
                onClick={() => newCanchaHandler()}
              >
                <span>
                  <BsPlusCircle /> Crear Evento
                </span>
              </Button>
            </div>
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <Table responsive>
                <ListGroup>
                  <ListGroupItem>
                    <Row className="align-items-center table-order">
                      {/* <Col md={2}>
                    <span>ID</span>
                  </Col> */}
                      <Col as={"th"} md={4}>
                        <span>Nombre</span>
                      </Col>
                      <Col as={"th"} md={4}>
                        Deporte
                      </Col>
                      <Col md={4}></Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
                <ListGroup>
                  {currentPosts.map((cancha) => (
                    <ListGroupItem>
                      <Row
                        className="align-items-center table-order"
                        id="data"
                        key={cancha._id}
                      >
                        {/* <Col md={2}>{cancha._id}</Col> */}
                        <Col md={4}>{cancha.nombre}</Col>
                        <Col md={4}>{cancha.deporte}</Col>
                        <Col md={4}>
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Ver Detalles</Tooltip>
                            }
                          >
                            <Button
                              md={4}
                              variant="outline-info"
                              className="ml-1"
                              //className="btn-outline-info btn light"
                              onClick={() => detailsCanchaHandler(cancha)}
                            >
                              <BsFileEarmarkRichtext />
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Editar</Tooltip>
                            }
                          >
                            <Button
                              md={4}
                              variant="outline-secondary"
                              className="mx-2"
                              //className="btn-outline-success btn light"
                              onClick={() => editCanchaHandler(cancha)}
                            >
                              <BsPencilSquare />
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Eliminar</Tooltip>
                            }
                          >
                            <Button
                              md={4}
                              variant="outline-danger"
                              className="mr-1"
                              //className="btn btn-outline-danger"
                              onClick={() => deleteCanchaHandler(cancha)}
                            >
                              <BsTrash />
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Table>
            )}
          </Container>
        </div>
        <Pagination
          className="pagination-bar"
          totalCount={itemQuantity}
          onPageChange={onPageChange}
          currentPage={currentPage}
          pageSize={maxitemsPerPage}
        ></Pagination>
      </div>
    </div>
  );
}

export default EventList;
