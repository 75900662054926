import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { BsCalculator } from "react-icons/bs";
import { FaTruck } from "react-icons/fa";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getError, maxitemsPerPage } from "../utils";
import "../App.css";
import { Store } from "../Store";
import BootstrapCarouselComponent from "../components/Carousel";
import ShowSubdomains from "../components/ShowSubdomains.js";
import { ColorContext } from "../components/ColorContext.js";
import LoadingBox from "../components/LoadingBox.js";
import MessageBox from "../components/MessageBox.js";
import "./Screens.css";
import axios from "axios";
import EventCard from "../components/EventCard/EventCard.js";
import { Row, Container } from "react-bootstrap";
import Pagination from "../components/Pagination";
import { BsFileEarmarkRichtext } from "react-icons/bs";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, subdomain: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "FETCH_EVENTOS_REQUEST":
      return { ...state, loading: true };
    case "FETCH_EVENTOS_SUCCESS":
      return {
        ...state,
        eventos: action.payload,
        loading: false,
        itemQuantity: action.payload.length,
      };
    case "FETCH_EVENTOS_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function SubdomainHomeScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { baseURL } = params;

  const [
    { loading, subdomain, eventos, successDelete, itemQuantity, error },
    dispatch,
  ] = useReducer(reducer, {
    subdomain: {},
    eventos: [],
    loading: true,
    error: "",
    itemQuantity: 0,
  });

  const { updateColors } = useContext(ColorContext);

  const { state } = useContext(Store);
  const { userInfo } = state;

  const [loadingColors, setLoadingColors] = useState(true);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * maxitemsPerPage;
  const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
  let currentPosts = eventos.slice(indexOfFirstPost, indexOfLastPost);
  //change page
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    currentPosts = eventos.slice(indexOfFirstPost, indexOfLastPost);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_EVENTOS_REQUEST" });
        const { data } = await axios.get(`/api/events`);
        dispatch({ type: "FETCH_EVENTOS_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_EVENTOS_FAIL", payload: getError(err) });
      }
    };

    const fetchSubdmains = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(`/api/subdomains/baseURL/${baseURL}`);
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
    };

    const setColors = async () => {
      setLoadingColors(true);
      const colors = await axios.get(`/api/colors/bySubdomainURL/${baseURL}`);
      if (colors) {
        await updateColors(colors.data);
      } else {
        const colors = await axios.get("/api/colors/general");
        await updateColors(colors.data);
      }
      setLoadingColors(false);
    };

    setColors();
    fetchSubdmains();
    fetchData();
  }, [userInfo, successDelete]);

  return loadingColors ? (
    <LoadingBox></LoadingBox>
  ) : (
    <div className="w-100">
      <Helmet>
        <title>Lista de Eventos</title>
      </Helmet>
      <BootstrapCarouselComponent></BootstrapCarouselComponent>
      <Container>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <Row className="justify-content-center m-4 align-items-stretch">
            <div className="borderLine border-1 mb-2 mt-2"></div>
          <h2
            style={{ textAlign: "center", fontWeight: "bold" }}
            className="mb-2"
          >
            EVENTOS{" "}
          </h2>
          <div className="borderLine border-1 mb-2"></div>
            {currentPosts.map((evento) => (
              <div key={evento.id} className="col-lg-4 col-md-6 mb-4">
                <EventCard
                  event={evento}
                  onClick={() =>
                    navigate(
                      `/subdomain/${subdomain.baseURL}/evento/${evento._id}`
                    )
                  }
                ></EventCard>
              </div>
            ))}
          </Row>
        )}
        <Pagination
          className="pagination-bar"
          totalCount={itemQuantity}
          onPageChange={onPageChange}
          currentPage={currentPage}
          pageSize={maxitemsPerPage}
        ></Pagination>
      </Container>
    </div>
  );
}
export default SubdomainHomeScreen;
