import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Card,
  Stack,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, lugares: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        canc: action.payload,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};

function ViewVenue() {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [link, setLink] = useState();
  const [image, setImage] = useState();
  const [sections, setSections] = useState([]);

  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(`/api/venues/${id}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
        setName(result.data.name);
        setAddress(result.data.address);
        setLink(result.data.link);
        setImage(result.data.image);
        setSections(result.data.sections);
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
      // setAccounts(result.data);
    };
    fetchData();
  }, [userInfo, id]);

  return (
    <div>
      <div>{loading && <LoadingBox></LoadingBox>}</div>
      <Container
        fluid
        style={{ maxHeight: "90vh", overflowY: "auto", marginBottom: "4rem" }}
      >
        {" "}
        <Helmet>
          <title>Detalles del Lugar</title>
        </Helmet>
        <Container>
          <Row className="mt-5 mb-8 vh-100 d-flex justify-content-center">
            <Col md={10} lg={8} xs={12}>
              <div className="borderLine"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Detalles del Lugar
                    </h2>
                    <div className="mb-3">
                      <Form>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="name"
                          >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              defaultValue={name}
                              disabled
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="name"
                          >
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              defaultValue={link}
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Row>
                            <Form.Group
                              as={Col}
                              className="mb-3"
                              controlid="name"
                            >
                              <Form.Label>Direccion</Form.Label>
                              <Form.Control
                                type="address"
                                required
                                defaultValue={address}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Row>
                        </Row>
                        <Row>
                          <Col className="d-flex justify-content-center align-items-center">
                            {image && image.fileLink ? (
                              <img
                                alt="logo_photo"
                                src={image.fileLink}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            ) : (
                              <img
                                alt="logo_photo"
                                src="/images/emptyPhoto.png"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <br />
                        <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                          Secciones
                        </h2>
                        <br />
                        {sections.map((section) => {
                          return (
                            <Row>
                              <Col>
                                <Form.Group
                                  as={Col}
                                  className="mb-3"
                                  controlid="name"
                                >
                                  <Form.Label>Nombre</Form.Label>
                                  <Form.Control
                                    type="text"
                                    required
                                    defaultValue={section.name}
                                    disabled
                                  ></Form.Control>
                                </Form.Group>
                              </Col>

                              <Col>
                                <Form.Group
                                  as={Col}
                                  className="mb-3"
                                  controlid="name"
                                >
                                  <Form.Label>Asientos</Form.Label>
                                  <Form.Control
                                    type="text"
                                    required
                                    defaultValue={section.seats}
                                    disabled
                                  ></Form.Control>
                                </Form.Group>
                              </Col>
                              <Col>
                                {section.image && section.image.fileLink ? (
                                  <img
                                    alt="logo_photo"
                                    src={section.image.fileLink}
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="logo_photo"
                                    src="/images/emptyPhoto.png"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                    }}
                                  />
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default ViewVenue;
