import React from 'react';
import './Tab.css'; // Import your custom styling for the Tab component

function Tab({ status, active, onClick, count }) {
	const tabClass = active ? 'selected' : ''; // Determine the class based on active status

	return (
		<li className={`tab ${tabClass}`} onClick={onClick}>
			{status}
			{count > 0 && <span className="badge">{count}</span>}

		</li>
	);
}

export default Tab;
