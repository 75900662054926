import React from "react";
import Spinner from "react-bootstrap/Spinner";
const LoadingBox = ({ height }) => {
  const containerStyle = {
    height: height || "100vh", // Default height
    verticalAlign: "middle",
    display: "table-cell",
    width: "100vw",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  };

  return (
    <div className="text-center py-5" style={containerStyle}>
      <Spinner
        animation="border"
        role="status"
        style={{ color: "var(--secondaryColor)" }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
export default LoadingBox;
