import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  Tooltip,
  Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  BsArrowLeftCircle,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import bcrypt from "bcryptjs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loadingFetch: true };
    case "FETCH_SUCCESS":
      return { ...state, reserva: action.payload, loadingFetch: false };
    case "FETCH_FAIL":
      return { ...state, loadingFetch: false, errorFetch: action.payload };
    default:
      return state;
  }
};
function ConfirmarPago() {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const { state } = useContext(Store);
  const { userInfo } = state;

  const [comprobante, setComprobante] = useState("");
  const [titularCuenta, setTitular] = useState();
  const [nroTransaccion, setNroTransaccion] = useState();
  const [uploading, setUploading] = useState(false);
  const [validated, setValidated] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [{ loadingFetch, reserva }, dispatch] = useReducer(reducer, {
    reserva: {},
    loadingFetch: true,
    error: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(`/api/reservas/${id}`);
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: error.message });
      }
    };
    fetchData();
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      uploadComprobante();
    }
    setValidated(true);
  };

  const uploadComprobante = async () => {
    setUploading(true);
    const file = comprobante[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    if (comprobante) {
      formData.append("documentId", comprobante._id);
    }
    //const multiPart = { "content-type": "multipart/form-data" };
    try {
      const response = await axios.post(
        `/api/reservas/uploadComprobante/`,
        formData
      );
      confirmarPago(response.data);
    } catch (ex) {
      setUploading(false);
      toast.error(getError(ex));
    }
  };

  const confirmarPago = async (comprobante) => {
    try {
      const { data } = await axios.put(
        `/api/reservas/confirmarReserva/${reserva._id}`,
        {
          responsableConfirmacion: userInfo,
          comprobante,
          nroTransaccion,
          titularCuenta,
        }
      );
      setUploading(false);
      toast.success("Compra confirmada");
      navigate("/AdminScreen/reservas");
    } catch (ex) {
      setUploading(false);
      toast.error(getError(ex));
    }
  };

  return (
    <div>
      <div>
        {(loadingFetch || uploading) && <LoadingBox></LoadingBox>}
        {/* <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Compra</Modal.Title>
          </Modal.Header>
          <Modal.Body>Seguro desea confirmar pago?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={(e) => uploadComprobante()}>Confirmar</Button>
          </Modal.Footer>
        </Modal> */}
      </div>
      <Container className="large-container">
        <Helmet>
          <title>Confirmar Compra</title>
        </Helmet>
        <Container fluid>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border border-3 border-primary"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h5 className=" mb-4 text-align-center ">
                      Confirmar Compra del <b>{reserva.numero}</b> de la{" "}
                      <b>{reserva.cancha ? reserva.cancha.nombre : null}</b>
                    </h5>
                    <div className="mb-3">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="lote"
                          >
                            <Form.Label>Lote(s)</Form.Label>
                            <Form.Control
                              type="text"
                              disabled
                              required
                              value={
                                reserva.lotes ? reserva.lotes.join("-") : ""
                              }
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group as={Col} md={6} controlId="cancha">
                            <Form.Label>Cancha</Form.Label>
                            <Form.Control
                              type="text"
                              disabled
                              required
                              //placeholder={reserva.cancha ? reserva.cancha.nombre : null }
                              value={
                                reserva.cancha ? reserva.cancha.nombre : null
                              }
                            />
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md={5}
                            className="mb-3"
                            controlid="transaccion"
                          >
                            <Form.Label>Nro. de Transacción</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              onChange={(e) =>
                                setNroTransaccion(e.target.files)
                              }
                            ></Form.Control>
                          </Form.Group>

                          <Form.Group as={Col} md={7} controlId="titular">
                            <Form.Label>Titular de la cuenta</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              onChange={(e) => setTitular(e.target.files)}
                            />
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md={12}
                            className="mb-3"
                            controlid="comprobante"
                          >
                            <Form.Label>Comprobante</Form.Label>
                            <Form.Control
                              type="file"
                              required
                              onChange={(e) => setComprobante(e.target.files)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Debes subir un comprobante de pago
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button
                            type="submit"
                            className="mx-auto"
                            //onClick={(e) => uploadComprobante()}
                          >
                            Confirmar Compra
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default ConfirmarPago;
