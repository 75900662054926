import React, {
  useLayoutEffect,
  useReducer,
  useState,
  useContext,
} from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import { createGlobalStyle } from "styled-components";
import { ColorContext } from "./components/ColorContext.js";
import ThemedComponent from "./components/GlobalStyles.js";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import MessageBox from "./components/MessageBox";
import Navbarr from "./components/Navbar";
import Footerr from "./components/Footer";
import AdminFooter from "./components/AdminFooter/AdminFooter";
import ScrollToTop from "./components/ScrollToTop";
import AdminNavbar from "./components/AdminNavbar/AdminNavbar.js";
import LoadingBox from "./components/LoadingBox";
import HomeScreen from "./screens/HomeScreen.jsx";
import SubdomainHomeScreen from "./screens/SubdomainHomeScreen";
import SignInScreen from "./screens/Auth/SignInScreen.jsx";
import ResetPassword from "./screens/Auth/ResetPassword.jsx";
import SignupScreen from "./screens/Auth/SignUpScreen.jsx";
import ContactUs from "./screens/AboutUs/ContactUs.jsx";
import Shipping from "./screens/AboutUs/Shipping.jsx";
import ProfileScreen from "./screens/ProfileScreen.jsx";
import AdminHome from "./screens/HomeScreens/AdminHome.jsx";
import ChangePassword from "./screens/Auth/ChangePassword.jsx";
import VerifyUser from "./screens/Auth/VerifyUser.jsx";
import FaqPage from "./screens/FaqPage/FaqPage.jsx";
import AdminConfigScreen from "./screens/AdminConfigScreen/AdminConfigScreen.jsx";
import AddAmin from "./screens/Users/NewAdmin.jsx";
import EditUser from "./screens/Users/EditUser.jsx";
import ViewUser from "./screens/Users/ViewUser.jsx";
import AdminList from "./screens/Users/AdminList.jsx";
import LotesList from "./screens/Lotes/LotesList.jsx";
import NewLote from "./screens/Lotes/NewLote.jsx";
import Eventos from "./screens/Eventos/EventList.jsx";
import NewEvent from "./screens/Eventos/NewEvent.jsx";
import EditEvent from "./screens/Eventos/EditEvent.jsx";
import ViewEvent from "./screens/Eventos/ViewEvent.jsx";
import Ventas from "./screens/Reservas/ListaVentas.jsx";
import AddReserva from "./screens/Reservas/NuevaReserva.jsx";
import ConfirmarReserva from "./screens/Reservas/ConfirmarReserva.jsx";
import ConfirmarPago from "./screens/Reservas/ConfirmarPago.jsx";
import VerPredio from "./screens/Cliente/VerPredio.jsx";
import LotesCancha from "./screens/Lotes/LotesCancha.jsx";
import ListaJugadores from "./screens/Jugadores/ListaJugadores.jsx";
import NuevoJugador from "./screens/Jugadores/NuevoJugador.jsx";
import EditJugador from "./screens/Jugadores/EditJugador.jsx";
import ViewJugador from "./screens/Jugadores/ViewJugador.jsx";
import AddSubdomain from "./screens/SubdomainsConfig/AddSubdomain";
import EditSubdomain from "./screens/SubdomainsConfig/EditSubdomain";
import AdminSubdomainsConfig from "./screens/SubdomainsConfig/AdminSubdomainsConfig";
import AddBanner from "./screens/Banners/AddBanner.jsx";
import AdminBanners from "./screens/Banners/AdminBanners.jsx";
import EditBanner from "./screens/Banners/EditBanner.jsx";
import AddLugar from "./screens/Lugares/newLugar.jsx";
import EventScreen from "./screens/PublicEvents/EventoScreen.jsx";
import EditVenue from "./screens/Venues/EditVenue.jsx";
import VenueList from "./screens/Venues/VenueList.jsx";
import NewVenue from "./screens/Venues/NewVenue.jsx";
import ViewVenue from "./screens/Venues/ViewVenue.jsx";
import MyTicketsScreen from "./screens/MyTickets/MyTicketsScreen.jsx";
import AdminBundleScreen from "./screens/AdminBundles/AdminBundleScreen.jsx";
import NewBundleScreen from "./screens/NewBundle/NewBundleScreen.jsx";
import VentasTruco from "./screens/VentasTruco/VentasTruco.jsx";
import VentasTrucoNew from "./screens/VentasTrucoNew/VentasTrucoNew.jsx";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        gotaSettings: action.payload.settings,
        loading: false,
      };
    case "FETCH_COLOR_REQUEST":
      return { ...state, loadingTheme: true };
    case "FETCH_COLOR_SUCCESS":
      return {
        ...state,
        colors: action.payload.colorsObj,
        loadingTheme: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function App() {
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: false,
    error: "",
  });

  const [isAdminScreen, setIsAdminScreen] = useState(false);

  useLayoutEffect(() => {
    setIsAdminScreen(window.location.pathname.includes("/AdminScreen"));

    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data: settings } = await axios.get("/api/config/public");

        dispatch({ type: "FETCH_SUCCESS", payload: { settings } });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: error.message });
      }
    };

    fetchData();
  }, [window.location.pathname]);

  const mainStyle = {
    paddingLeft: isAdminScreen ? "60px" : "", // Apply padding if current route includes '/AdminScreen'
  };

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <ThemedComponent />
          <BrowserRouter>
            <ScrollToTop />
            <div className="d-flex flex-column site-container">
              <Navbarr />
              <AdminNavbar />
              <main style={mainStyle}>
                <Routes>
                  <Route path="/" element={<HomeScreen />} />
                  <Route
                    path="/subdomain/:baseURL"
                    element={<SubdomainHomeScreen />}
                  />

                  <Route
                    path="/AdminScreen/subdomain/:baseURL"
                    element={<VentasTrucoNew />}
                  />
                  
                  <Route
                    path="/subdomain/:baseURL/evento/:id"
                    element={<EventScreen />}
                  />
                  <Route
                    path="/subdomain/:baseURL/evento/:id/:ticketOrder"
                    element={<EventScreen />}
                  />
                  <Route
                    path="/AdminScreen/subdomain/:baseURL/evento/:id"
                    element={<EventScreen />}
                  />
                  <Route
                    path="/AdminScreen/subdomain/:baseURL/evento/:id/:ticketOrder"
                    element={<EventScreen />}
                  />
                  <Route path="/signin" element={<SignInScreen />} />
                  <Route
                    path="/subdomain/:baseURL/signin"
                    element={<SignInScreen />}
                  />
                  <Route path="/reset-password/" element={<ResetPassword />} />
                  <Route path="/signup" element={<SignupScreen />} />
                  <Route
                    path="/subdomain/:baseURL/signup"
                    element={<SignupScreen />}
                  />
                  <Route path="/profile" element={<ProfileScreen />} />
                  <Route
                    path="/change-password/:token"
                    element={<ChangePassword />}
                  />
                  <Route path="/verify-admin/:token" element={<VerifyUser />} />
                  <Route path="/contact" element={<ContactUs />} />
                  <Route path="/bases" element={<Shipping />} />
                  <Route path="/AdminScreen" element={<VentasTrucoNew />} />
                  <Route
                    path="/AdminScreen/bundles"
                    element={<AdminBundleScreen />}
                  />
                  <Route
                    path="/AdminScreen/new-bundle"
                    element={<NewBundleScreen />}
                  />
                  <Route path="/AdminScreen/newAdmin" element={<AddAmin />} />
                  <Route path="/AdminScreen/newLugar" element={<AddLugar />} />

                  <Route
                    path="/AdminScreen/editVenue/:id"
                    element={<EditVenue />}
                  />
                  <Route path="/AdminScreen/venues" element={<VenueList />} />
                  <Route path="/AdminScreen/newVenue" element={<NewVenue />} />
                  <Route
                    path="/AdminScreen/venue/:id"
                    element={<ViewVenue />}
                  />

                  <Route path="/AdminScreen/admin/:id" element={<EditUser />} />
                  <Route
                    path="/AdminScreen/editAdmin/:id"
                    element={<EditUser />}
                  />
                  <Route path="/AdminScreen/admin/:id" element={<ViewUser />} />
                  <Route path="/AdminScreen/admins" element={<AdminList />} />
                  <Route path="/AdminScreen/lotes" element={<LotesList />} />
                  <Route path="/AdminScreen/newLote" element={<NewLote />} />
                  {/* <Route
                    path="/AdminScreen/ventas/search"
                    element={<Ventas />}
                  /> */}
                  <Route
                    path="/AdminScreen/ventas/search"
                    element={<VentasTrucoNew />}
                  />
                  <Route path="/AdminScreen/events" element={<Eventos />} />
                  <Route path="/AdminScreen/newEvent" element={<NewEvent />} />
                  <Route
                    path="/AdminScreen/editEvent/:id"
                    element={<EditEvent />}
                  />
                  <Route
                    path="/AdminScreen/event/:id"
                    element={<ViewEvent />}
                  />

                  <Route path="/reservarLotes" element={<AddReserva />} />
                  <Route
                    path="/confirmarReserva/:id"
                    element={<ConfirmarReserva />}
                  />
                  <Route
                    path="/AdminScreen/confirmarPago/:id"
                    element={<ConfirmarPago />}
                  />
                  <Route path="/verPredio" element={<VerPredio />} />
                  <Route path="/lotesCancha/:id" element={<LotesCancha />} />
                  <Route
                    path="/AdminScreen/usuarios/"
                    element={<ListaJugadores />}
                  />
                  <Route
                    path="/AdminScreen/newUser/"
                    element={<NuevoJugador />}
                  />
                  <Route
                    path="/AdminScreen/user/:id"
                    element={<ViewJugador />}
                  />
                  <Route
                    path="/AdminScreen/editUser/:id"
                    element={<EditJugador />}
                  />
                  <Route
                    path="/AdminScreen/AddSubdomain"
                    element={<AddSubdomain />}
                  />
                  <Route
                    path="/AdminScreen/EditSubdomain/:id"
                    element={<EditSubdomain />}
                  />
                  <Route
                    path="/AdminScreen/AdminSubdomain"
                    element={<AdminSubdomainsConfig />}
                  />
                  <Route
                    path="/AdminScreen/Config"
                    element={<AdminConfigScreen />}
                  />
                  <Route
                    path="/AdminScreen/AdminBanners"
                    element={<AdminBanners />}
                  />
                  <Route
                    path="/AdminScreen/AdminBanners/AddBanner"
                    element={<AddBanner />}
                  />
                  <Route
                    path="/AdminScreen/AdminBanners/EditBanner/:id"
                    element={<EditBanner />}
                  />
                  <Route path="/faq" element={<FaqPage />} />
                  <Route path="/myTickets" element={<MyTicketsScreen />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </main>
            </div>
            <Footerr />
            <AdminFooter />
          </BrowserRouter>
        </>
      )}
    </>
  );
}

export default App;
