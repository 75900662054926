import LoadingBox from "../../components/LoadingBox";
import React, { useContext, useReducer, useState, useEffect } from "react";
import { Store } from "../../Store";
import { Helmet } from "react-helmet-async";
import {
  Form,
  Button,
  Modal,
  Col,
  Container,
  Row,
  Card,
  Stack,
  InputGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  BsArrowLeftCircle,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import bcrypt from "bcryptjs";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, user: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "FETCH_SUBDOMAINS_REQUEST":
      return { ...state, loadingSubdomains: true };
    case "FETCH_SUBDOMAINS_SUCCESS":
      return { ...state, subdomains: action.payload, loadingSubdomains: false };
    case "FETCH_SUBDOMAINS_FAIL":
      return { ...state, loadingSubdomains: false, error: action.payload };
    default:
      return state;
  }
};

function EditAdmin() {
  const params = useParams();
  const navigate = useNavigate();

  const { id } = params;
  const [{ loading, error, user, subdomains, loadingSubdomains }, dispatch] =
    useReducer(reducer, {
      user: {},
      loading: true,
      error: "",
      subdomains: [],
      loadingSubdomains: true,
    });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [subdomain, setSubdomain] = useState(null);
  const [isAdmin, setIsAdmin] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(`/api/admins/${id}`);
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
        setName(result.data.nombre);
        setEmail(result.data.email);
        setSubdomain(result.data.subdomain);
        setPassword(result.data.password);
        setIsAdmin(result.data.isAdmin);
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
      // setAccounts(result.data);
    };

    const fetchSubdomains = async () => {
      try {
        dispatch({ type: "FETCH_SUBDOMAINS_REQUEST" });
        const { data } = await axios.get("/api/subdomains");
        dispatch({ type: "FETCH_SUBDOMAINS_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_SUBDOMAINS_FAIL", payload: getError(err) });
      }
    };

    fetchSubdomains();
    fetchData();
  }, [id]);

  const editUserHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const { data } = await axios.put(
        `/api/admins/${user._id}`,
        {
          name,
          email,
          isAdmin,
          subdomain,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      // deletePictureHandler(image);
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      ctxDispatch({ type: "USER_UPDATE_SUCCESS", payload: data });
      localStorage.setItem("user", JSON.stringify(data));

      toast.success("Usuario actualizado Correctamente");
      navigate("/AdminScreen/users");
    } catch (err) {
      dispatch({ type: "FETCH_FAIL" });
      toast.error(getError(err));
    }
  };

  return (
    <div>
      {loading && <LoadingBox></LoadingBox>}
      {/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
      <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Guardar Cambios</Modal.Title>
        </Modal.Header>
        <Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={editUserHandler}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <Container className="large-container">
        <Helmet>
          <title>Editar Usuario</title>
        </Helmet>
        <Container fluid>
          <Row className="mt-5 vh-100 d-flex justify-content-center">
            <Col md={8} lg={6} xs={12}>
              <div className="borderLine"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Editar Usuario
                    </h2>
                    <div className="mb-3">
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="mb-3" controlid="name">
                          <Form.Label>Nombre:</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            defaultValue={user.nombre}
                            onChange={(e) => setName(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3" controlid="email">
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            type="email"
                            required
                            defaultValue={user.email}
                            onChange={(e) =>
                              setEmail(e.target.value.toLowerCase())
                            }
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="subdomain">
                          <Form.Label>Equipo</Form.Label>
                          <Form.Select
                            type="text"
                            disabled={!userInfo.isSuperAdmin}
                            onChange={(e) => {
                              setSubdomain(e.target.value);
                            }}
                            required
                            isValid={subdomain !== null}
                          >
                            {subdomain ? (
                              <option key={subdomain._id} value={subdomain._id}>
                                {subdomain.name}
                              </option>
                            ) : (
                              <option key={0} value={null}>
                                Selecciona un equipo
                              </option>
                            )}
                            {subdomains.map((subdomain) => (
                              <option key={subdomain._id} value={subdomain._id}>
                                {subdomain.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        {/* <Form.Label>Contraseña</Form.Label>
                        <InputGroup
                          className="mb-3"
                          style={{ padding: 0 }}
                          controlid="password"
                        >
                          <Form.Control
                            type={passwordType}
                            required
                            onChange={(e) =>
                              setPassword(bcrypt.hashSync(e.target.value))
                            }
                          />
                          <Button
                            variant="btn btn--secondary"
                            className="eyeButton"
                            id="button-addon2"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <BsFillEyeSlashFill />
                            ) : (
                              <BsFillEyeFill />
                            )}
                          </Button>
                        </InputGroup> */}

                        <Form.Group className="mb-3 col-3" controlId="psw">
                          <Form.Label>Es Administrador?</Form.Label>
                          <Form.Check
                            type="checkbox"
                            checked={isAdmin}
                            onChange={(e) => setIsAdmin(e.target.value)}
                          ></Form.Check>
                        </Form.Group>

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button
                            type="submit"
                            className="mx-auto"
                            onClick={handleShow}
                          >
                            Gardar Cambios
                          </Button>
                        </Stack>
                        {/* <div className="mb-3">
                          
                       
                        </div> */}
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default EditAdmin;
