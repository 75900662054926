import Axios from "axios";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import React, { useContext, useEffect, useState, useReducer } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import "../../App.css";
import "../Screens.css";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { ColorContext } from "../../components/ColorContext";
import LoadingBox from "../../components/LoadingBox";
import axios from "axios";

export default function SignInScreen() {
  const navigate = useNavigate();
  const { location } = useLocation();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { baseURL } = params;

  const [inSubdomain, setInSubdomain] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const { updateColors } = useContext(ColorContext);

  const [loadingColors, setLoadingColors] = useState(true);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post("/api/admins/signin", {
        email,
        password,
      });
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      if (data.isSuperAdmin) {
        navigate("/AdminScreen");
      } else if (data.isAdmin) {
        navigate(`/AdminScreen/subdomain/${data.subdomain.baseURL}`);
      } else {
        navigate(`/subdomain/${data.subdomain.baseURL}`);
      }
    } catch (err) {
      toast.error(getError(err));
    }
  };
    //#region REQUESTS 
    const fetchData = async () => {
      await Promise.all([setColors(),fetchSubdmain()]);
    }
  const verifyUser = async (token) => {
    try {
      const { data } = await axios.put(`/api/admins/verifyUser/${token}`);
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      navigate("/");
      toast.success("Usuario verificado");
    } catch (err) {
      console.error(err); 
      toast.error("No se pudo verificar el usuario");
    }
  }
    const fetchSubdmain = async () => {
      try {
        const result = await axios.get(`/api/subdomains/baseURL/${baseURL}`);
        if (result.data === "") {
          setInSubdomain(false);
        } else {
          setInSubdomain(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const setColors = async () => {
      setLoadingColors(true);
      const colors = await axios.get(`/api/colors/bySubdomainURL/${baseURL}`);
      if (colors.data !== "") {
        await updateColors(colors.data);
      } else {
        const colors = await axios.get("/api/colors/general");
        await updateColors(colors.data);
      }
      setLoadingColors(false);
    };
    //#endregion 
  useEffect(() => {
    if (userInfo) {
      navigate("/");
    } else {
      fetchData();
      const token = searchParams.get("verifyToken");
      if(token) {
        verifyUser(token);
      }
    }
  }, [navigate, userInfo]);

  return loadingColors ? (
    <LoadingBox></LoadingBox>
  ) : (
    <Container>
      <Helmet>
        <title>Iniciar sesión</title>
      </Helmet>
      <Container fluid>
        <Row className="mt-5 mb-0 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="borderLine"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                    Iniciar Sesión
                  </h2>
                  <div className="mb-3">
                    <Form onSubmit={submitHandler}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Label className="text-center">E-mail</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          }
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="password">
                        <Form.Label className="text-center">
                          Contraseña
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Control
                            type={passwordType}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ marginRight: "10px" }}
                          />
                          <Button
                            variant="btn btn--secondary"
                            className="eyeButton"
                            id="button-addon2"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <BsFillEyeSlashFill />
                            ) : (
                              <BsFillEyeFill />
                            )}
                          </Button>
                        </div>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <p className="small">
                          <a className="text-primary" href="/reset-password">
                            Olvidó su contraseña?
                          </a>
                        </p>
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          Iniciar Sesión
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        No está registrado?{" "}
                        {inSubdomain ? (
                          <a
                            href={`/subdomain/${baseURL}/signup`}
                            className="text-primary fw-bold"
                          >
                            Registrarse
                          </a>
                        ) : (
                          <a href="/signup" className="text-primary fw-bold">
                            Registrarse
                          </a>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
