import React from "react";
import Axios from "axios";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import InputGroup from "react-bootstrap/InputGroup";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import "../Screens.css";
import { ColorContext } from "../../components/ColorContext";
import LoadingBox from "../../components/LoadingBox";
import axios from "axios";

export default function SignupScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { updateColors } = useContext(ColorContext);

  const [loadingColors, setLoadingColors] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const params = useParams();
  const { baseURL } = params;

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [repeatPasswordType, setRepeatPasswordType] = useState("password");
  const [subdomain, setSubdomain] = useState(null);
  const [subdomains, setSubdomains] = useState([]);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleRepeatPassword = () => {
    if (repeatPasswordType === "password") {
      setRepeatPasswordType("text");
      return;
    }
    setRepeatPasswordType("password");
  };

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const submitHandler = async (e) => {
    if (password !== confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }
    try {
      const { data } = await Axios.post("/api/admins/signup", {
        name,
        email,
        subdomain,
        password,
      });
      toast.success(data.message);
      navigate("/");
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    const fetchSubdmains = async () => {
      try {
        const result = await axios.get(`/api/subdomains`);
        setSubdomains(result.data);
      } catch (error) {
        console.error(error);
      }
    };

    const getSubdomain = async () => {
      setLoadingData(true);
      try {
        const result = await axios.get(`/api/subdomains/baseURL/${baseURL}`);
        if (result.data) {
          setSubdomain(result.data);
        }
        setLoadingData(false);
      } catch (error) {
        console.error(error);
      }
    };

    const setColors = async () => {
      setLoadingColors(true);
      const colors = await axios.get(`/api/colors/bySubdomainURL/${baseURL}`);
      if (colors.data !== "") {
        await updateColors(colors.data);
      } else {
        const colors = await axios.get("/api/colors/general");
        await updateColors(colors.data);
      }
      setLoadingColors(false);
    };
    if (userInfo) {
      navigate("/");
    } else {
      setColors();
      getSubdomain();
      fetchSubdmains();
    }
  }, [navigate, userInfo]);

  return loadingColors || loadingData ? (
    <LoadingBox></LoadingBox>
  ) : (
    <Container>
      <Helmet>
        <title>Registro</title>
      </Helmet>
      <Container fluid>
        <Row className="mt-5 mb-0 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="borderLine"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                    Registro
                  </h2>
                  <div className="mb-3">
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Nombre:</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                          type="email"
                          required
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          }
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="subdomain">
                        <Form.Label>Equipo</Form.Label>
                        <Form.Select
                          type="text"
                          onChange={(e) => {
                            setSubdomain(e.target.value);
                          }}
                          required
                        >
                          {subdomain ? (
                            <option key={subdomain._id} value={subdomain._id}>
                              {subdomain.name}
                            </option>
                          ) : (
                            <option key={0} value={null}>
                              Selecciona un equipo
                            </option>
                          )}
                          {subdomains.map((subdomain) => (
                            <option key={subdomain._id} value={subdomain._id}>
                              {subdomain.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Label>Contraseña</Form.Label>
                      <InputGroup
                        className="mb-3"
                        style={{ padding: 0 }}
                        controlId="password"
                      >
                        <Form.Control
                          type={passwordType}
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                          variant="btn btn--secondary"
                          className="eyeButton"
                          id="button-addon2"
                          onClick={togglePassword}
                        >
                          {passwordType === "password" ? (
                            <BsFillEyeSlashFill />
                          ) : (
                            <BsFillEyeFill />
                          )}
                        </Button>
                      </InputGroup>
                      <Form.Label>Repetir Contraseña</Form.Label>
                      <InputGroup
                        className="mb-3"
                        style={{ padding: 0 }}
                        controlId="password"
                      >
                        <Form.Control
                          type={repeatPasswordType}
                          required
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <Button
                          variant="btn btn--secondary"
                          className="eyeButton"
                          id="button-addon2"
                          onClick={toggleRepeatPassword}
                        >
                          {repeatPasswordType === "password" ? (
                            <BsFillEyeSlashFill />
                          ) : (
                            <BsFillEyeFill />
                          )}
                        </Button>
                      </InputGroup>
                      <div className="mb-3">
                        <Button type="submit" onClick={submitHandler}>
                          Registrarse
                        </Button>
                      </div>
                      <div className="mb-3">
                        Ya tienes una cuenta?{" "}
                        {subdomain ? (
                          (console.info(subdomain),
                          (
                            <Link
                              to={`/subdomain/${subdomain.baseURL}/signin`}
                              className="text-primary fw-bold"
                            >
                              Inicia sesion
                            </Link>
                          ))
                        ) : (
                          <Link to={`/signin`} className="text-primary fw-bold">
                            Inicia sesion
                          </Link>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
