import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  BsArrowLeftCircle,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import bcrypt from "bcryptjs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";
import e from "cors";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loadingFetch: true };
    case "FETCH_SUCCESS":
      return { ...state, subdomains: action.payload, loadingFetch: false };
    case "FETCH_FAIL":
      return { ...state, loadingFetch: false, errorFetch: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};
function AddAdmin() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [subdomain, setSubdomain] = useState(null);
  const [telefono, setTelefono] = useState();
  const [passwordType, setPasswordType] = useState("password");

  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [{ loadingFetch, loadingCreate, subdomains }, dispatch] = useReducer(
    reducer,
    {
      loadingFetch: true,
      loadingCreate: false,
      error: "",
      subdomains: [],
    }
  );

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      handleShow();
    }
    setValidated(true);
  };

  const addUserHandler = async () => {
    if (subdomain === null) {
      toast.error("Seleccione un subdominio");
      return;
    }
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const { data } = await axios.post("/api/admins/createAdmin", {
        nombre,
        email,
        subdomain,
        telefono,
      });
      toast.success("Administrador creado");
      navigate(`/AdminScreen/users`);
    } catch (ex) {
      toast.error(getError(ex));
    }
  };

  useEffect(() => {
    const fetchSubdomains = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get("/api/subdomains");
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };

    fetchSubdomains();
  }, []);

  return (
    <div>
      <div>
        {loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
        <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Crear Administrador</Modal.Title>
          </Modal.Header>
          <Modal.Body>Seguro desea crear administrador?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={addUserHandler}>Confirmar</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Container className="large-container">
        <Helmet>
          <title>Crear Administrador</title>
        </Helmet>
        <Container fluid>
          <Row className="mt-5 vh-100 d-flex justify-content-center">
            <Col md={8} lg={6} xs={12}>
              <div className="borderLine"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Crear Administrador
                    </h2>
                    <div className="mb-3">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Form.Group className="mb-3" controlid="nombre">
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            onChange={(e) => setNombre(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlid="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlid="email">
                          <Form.Label>Telefono</Form.Label>
                          <Form.Control
                            type="number"
                            required
                            onChange={(e) => setTelefono(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="subdomain">
                          <Form.Label>Equipo</Form.Label>
                          <Form.Select
                            type="text"
                            disabled={!userInfo.isSuperAdmin}
                            onChange={(e) => {
                              setSubdomain(e.target.value);
                            }}
                            required
                            isValid={subdomain !== null}
                          >
                            {subdomain ? (
                              <option key={subdomain._id} value={subdomain._id}>
                                {subdomain.name}
                              </option>
                            ) : (
                              <option key={0} value={null}>
                                Selecciona un equipo
                              </option>
                            )}
                            {subdomains.map((subdomain) => (
                              <option key={subdomain._id} value={subdomain._id}>
                                {subdomain.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        {/* <Form.Label>Contraseña</Form.Label>
                        <InputGroup
                          className="mb-3"
                          style={{ padding: 0 }}
                          controlid="password"
                        >
                          <Form.Control
                            type={passwordType}
                            required
                            onChange={(e) => {
                              setPassword((e.target.value));
                              //setPassword(bcrypt.hashSync(e.target.value))
                            }
                            }
                          />
                          <Button
                            variant="btn btn--secondary"
                            className="eyeButton"
                            id="button-addon2"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <BsFillEyeSlashFill />
                            ) : (
                              <BsFillEyeFill />
                            )}
                          </Button>
                        </InputGroup> */}

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button
                            type="submit"
                            className="mx-auto"
                            //onClick={handleShow}
                          >
                            Crear Administrador
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default AddAdmin;
