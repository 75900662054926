import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";
import "./NewBundleScreen.css";
import PillInput from "../../components/PillInput/PillInput";
import moment from "moment";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_VENUE_SUCCESS":
      return { ...state, venues: action.payload, loading: false };
    case "FETCH_EVENTS_SUCCESS":
      return { ...state, loading: false, events: action.payload };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        canc: action.payload,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};

function NewEvent() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [lugar, setLugar] = useState();
  const [nombre, setNombre] = useState("");
  const [secciones, setSecciones] = useState([]);

  const [descripcion, setDescripcion] = useState("");
  const [ticketImage, setTicketImage] = useState();
  const [eventoImage, setEventoImage] = useState();
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [validated, setValidated] = useState(false);
  const [subdoMain, setSubdoMain] = useState(); //agregar form para agregar subDomain por distintos eventos;
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [{ loading, events, loadingCreate, canc, venues }, dispatch] =
    useReducer(reducer, {
      canc: {},
      venues: [],
      loading: true,
      events: [],
      loadingCreate: false,
      error: "",
    });

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      handleShow();
    }
    setValidated(true);
  };
  const sortDates = (a, b) => {
    return a > b ? 1 : a < b ? -1 : 0;
  };
  const formatRelatedDates = () => {
    const dates = relatedEvents.map((evt) => moment(evt.fecha)).sort(sortDates);
    return `${dates[0].format("LL")}  - ${dates[dates.length - 1].format(
      "LL"
    )}`;
  };
  const addBundleHandler = async () => {
    const nombre = relatedEvents.map((evt) => evt.nombre).join(" + ");
    const relatedEvts = relatedEvents.map((evt) => evt._id);

    try {
      dispatch({ type: "CREATE_REQUEST" });
      const { data } = await axios.post("/api/events/bundle", {
        bundleEvent: {
          nombre,
          lugar,
          dateRange: formatRelatedDates(),
          descripcion,
          ticketImage,
          eventoImage,
          relatedEvents: relatedEvts,
          subdoMain,
        },
      });
      const ticketCategories = secciones.reduce(
        (acc, cur) => [
          ...acc,
          { event: data._id, price: cur.price, section: cur._id },
        ],
        []
      );
      await axios.post("/api/ticketCategories/mass", { ticketCategories });
      dispatch({ type: "CREATE_SUCCESS", payload: data });
      toast.success("Evento creado");
      navigate(`/AdminScreen/events`);
    } catch (ex) {
      toast.error(getError(ex));
    }
  };

  const uploadPicture = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    if (ticketImage) {
      formData.append("documentId", ticketImage._id);
    }
    formData.append("type", "ticketImage");
    const multiPart = { "content-type": "multipart/form-data" };
    const response = await axios.post(
      "/api/config/uploadSettingsImg",
      formData,
      { ...multiPart, headers: { Authorization: `Bearer ${userInfo.token}` } }
    );
    setTicketImage(response.data);
    toast.success("imagen agregada correctamente");
  };

  const uploadEventoPicture = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    if (eventoImage) {
      formData.append("documentId", eventoImage._id);
    }
    formData.append("type", "canchaImage");

    const multiPart = { "content-type": "multipart/form-data" };
    const response = await axios.post(
      "/api/config/uploadSettingsImg",
      formData,
      { ...multiPart, headers: { Authorization: `Bearer ${userInfo.token}` } }
    );
    setEventoImage(response.data);
    toast.success("imagen agregada correctamente");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/venues`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_VENUE_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    fetchData();
    fetchEvents();
  }, [userInfo]);
  const fetchEvents = async () => {
    try {
      const { data } = await axios.get(`/api/events`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: "FETCH_EVENTS_SUCCESS", payload: data });
    } catch (err) {
      dispatch({ type: "FETCH_FAIL", payload: getError(err) });
    }
  };

  const handleVenueChange = (venue) => {
    setLugar(venue);
    var venueItem = venues.filter(function (item) {
      return item._id === venue;
    });
    setSecciones(venueItem[0].sections);
  };
  const addEvent = (e) => {
    const id = e.target.value;
    setSelectedEvent(id);
    if (id) {
      const event = events.find((ev) => ev._id === id);
      if (event) {
        setSelectedEvent("");
        setRelatedEvents([...relatedEvents, event]);
      } else {
        toast.error("No se encontro el evento.");
      }
    }
  };
  const removeEvent = (id) => {
    const indexToRemove = relatedEvents.findIndex((ev) => ev._id === id);
    const copy = [...relatedEvents];
    copy.splice(indexToRemove, 1);
    setRelatedEvents(copy);
  };

  const updateSection = (section, field, value) => {
    section[field] = value;
  };

  return (
    <div>
      <div>
        {loadingCreate && loading && <LoadingBox></LoadingBox>}
        <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Crear Cancha</Modal.Title>
          </Modal.Header>
          <Modal.Body>Seguro desea crear cancha?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={addBundleHandler}>Confirmar</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Container className="large-container">
        <Helmet>
          <title>Crear cuponera</title>
        </Helmet>
        <Container fluid>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border border-3 border-primary"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Crear cuponera
                    </h2>
                    <div className="mb-3">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Row>
                          <Col>
                            <Form.Group className="mb-3 col-6" controlId="">
                              <Form.Label>Subir Imagen Cuponera</Form.Label>
                              <Form.Control
                                type="file"
                                onChange={uploadEventoPicture}
                                name="canchaImage"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            {eventoImage && eventoImage.fileLink ? (
                              <img
                                alt="logo_photo"
                                src={eventoImage.fileLink}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            ) : (
                              <img
                                alt="logo_photo"
                                src="/images/emptyPhoto.png"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3 col-6" controlId="">
                              <Form.Label>Subir Imagen Tickets</Form.Label>
                              <Form.Control
                                type="file"
                                onChange={uploadPicture}
                                name="ticketImage"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            {ticketImage && ticketImage.fileLink ? (
                              <img
                                alt="logo_photo"
                                src={ticketImage.fileLink}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            ) : (
                              <img
                                alt="logo_photo"
                                src="/images/emptyPhoto.png"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Form.Group
                            as={Col}
                            className="mb-3 col-5"
                            controlid="eventos"
                            onChange={addEvent}
                          >
                            <Form.Label>Eventos incluidos</Form.Label>
                            <Form.Select
                              value={selectedEvent}
                              aria-label="Eventos incluidos"
                            >
                              <option value="">Selecciona los eventos</option>
                              {events.map((ev) => (
                                <option
                                  disabled={relatedEvents.some(
                                    (evt) => evt._id === ev._id
                                  )}
                                  value={ev._id}
                                >
                                  {ev.nombre}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Col xs={7}>
                            <div className="related-events">
                              {relatedEvents.map((ev, index) => (
                                <PillInput
                                  className={"mb-2"}
                                  value={ev.nombre}
                                  id={ev._id}
                                  removeHandler={removeEvent}
                                  key={ev.nombre + "ev" + index}
                                />
                              ))}
                            </div>
                          </Col>
                          <Row>
                            <Form.Group
                              as={Col}
                              className="mb-3"
                              controlid="nombre"
                            >
                              <Form.Label>Descripción</Form.Label>
                              <Form.Control
                                type="descripcion"
                                required
                                //disabled
                                // value={cantLotes}
                                onChange={(e) => setDescripcion(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Row>
                        </Row>
                        <Form.Group
                          as={Col}
                          className="mb-3"
                          controlId="deporte"
                        >
                          <Form.Label>Selecciona una locación</Form.Label>
                          <Form.Select
                            value={lugar} // Set the selected value to the state variable lugar
                            onChange={(e) => {
                              handleVenueChange(e.target.value);
                            }}
                          >
                            <option key="" value="">
                              -- Selecciona uno --
                            </option>
                            {venues.map((venue, index) => (
                              <option key={index._id} value={venue._id}>
                                {venue.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <br />
                        <h2 className="fw-bold mb-2 text-uppercase text-align-center ">
                          Secciones
                        </h2>
                        <br />
                        {secciones.map((section) => {
                          return (
                            <Row>
                              <Col>
                                <Form.Group
                                  as={Col}
                                  className="mb-3"
                                  controlid="name"
                                >
                                  <Form.Label>Nombre</Form.Label>
                                  <Form.Control
                                    type="text"
                                    required
                                    defaultValue={section.name}
                                    disabled
                                  ></Form.Control>
                                </Form.Group>
                              </Col>
                              {/* <Col>
                              <Form.Group
                                as={Col}
                                className="mb-3"
                                controlid="name"
                              >
                                <Form.Label>Filas</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  defaultValue={section.rows}
                                  disabled
                                ></Form.Control>
                              </Form.Group>
                            </Col> */}
                              <Col>
                                <Form.Group
                                  as={Col}
                                  className="mb-3"
                                  controlid="name"
                                >
                                  <Form.Label>Precio</Form.Label>
                                  <Form.Control
                                    type="text"
                                    required
                                    onChange={(e) =>
                                      updateSection(
                                        section,
                                        "price",
                                        e.target.value
                                      )
                                    }
                                  ></Form.Control>
                                </Form.Group>
                              </Col>
                            </Row>
                          );
                        })}

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button
                            type="submit"
                            className="mx-auto"
                            //onClick={handleShow}
                          >
                            Crear cuponera
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default NewEvent;
