import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SubdomainCard from "./Card";
import "./Carousel.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUBDOMAINS_REQUEST":
      return { ...state, loadingBannerFetch: true };
    case "FETCH_SUBDOMAINS_SUCCESS":
      return {
        ...state,
        subdomains: action.payload,
        loadingFetch: false,
        itemQuantity: action.payload.length,
      };
    case "FETCH_SUBDOMAINS_FAIL":
      return {
        ...state,
        loadingBannerFetch: false,
        errorBannerFetch: action.payload,
      };
    default:
      return state;
  }
};

const ShowSubdomains = () => {
  const [showComponent, setShowComponent] = useState(false);

  const [{ subdomains }, dispatch] = useReducer(reducer, {
    subdomains: [],
    loadingBannerFetch: true,
    errorBannerFetch: "",
  });

  useEffect(() => {
    if (showComponent === false) {
      const fetchData = async () => {
        try {
          dispatch({ type: "FETCH_SUBDOMAINS_REQUEST" });
          const { data } = await axios.get("/api/subdomains");
          dispatch({ type: "FETCH_SUBDOMAINS_SUCCESS", payload: data });
          setShowComponent(true);
        } catch (err) {
          dispatch({ type: "FETCH_SUBDOMAINS_FAIL", payload: err.message });
        }
      };
      fetchData();
    }
  }, []);

  return (
    <div className="w-80">
      <div>
        <Container fluid>
          <div className="borderLine border-1 mb-2 mt-2"></div>
          <h2
            style={{ textAlign: "center", fontWeight: "bold" }}
            className="mb-2"
          >
            SELECCIONA TU CLUB{" "}
          </h2>
          <div className="borderLine border-1 mb-2"></div>

          <Row
            className="mt-3"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
              textAlign: "-webkit-center",
            }}
          >
            {subdomains.map((subdomain) =>
              subdomain.image && subdomain.image.fileLink ? (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="mb-4"
                  key={subdomain._id}
                >
                  <SubdomainCard
                    title={subdomain.name}
                    baseURL={subdomain.baseURL}
                    imgSrc={subdomain.image.fileLink}
                  />
                </Col>
              ) : null
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ShowSubdomains;
