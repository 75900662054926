import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import React, {
  useContext,
  useLayoutEffect,
  useState,
  useReducer,
} from "react";
import { Store } from "../Store";
import axios from "axios";
import "../App.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ColorContext } from "./ColorContext";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_COMPANY_REQUEST":
      return { ...state, loading: true };
    case "FETCH_COMPANY_SUCCESS":
      return { ...state, GotaSettings: action.payload, loading: false };
    case "FETCH_COMPANY_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function Navbarr() {
  const [{ loading, GotaSettings }, dispatch] = useReducer(reducer, {
    GotaSettings: {},
    loading: true,
    error: "",
  });
  const [imageURL, setImageURL] = useState();
  const [baseURL, setBaseURL] = useState("");
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const { updateColors } = useContext(ColorContext);

  const location = useLocation();
  const route = location.pathname;
  // const params = useParams();
  // const { baseURL } = params;

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_COMPANY_REQUEST" });
        const { data } = await axios.get(`/api/config/public`);
        let imageURL = data.image.fileLink;

        // Check if the URL includes "/subdomain"
        if (window.location.href.includes("/subdomain")) {
          // Extract the baseURL from the URL
          const regex = /\/subdomain\/([^/]+)/;
          const match = window.location.href.match(regex);
          if (match && match.length > 1) {
            setBaseURL(match[1]);
            if (baseURL !== "") {
              const result = await axios.get(
                `/api/subdomains/baseURL/${baseURL}`
              );
              imageURL = result.data.image.fileLink;
            }
          }
        }

        setImageURL(imageURL);
        dispatch({ type: "FETCH_COMPANY_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_COMPANY_FAIL", payload: err.message });
      }
    };
    fetchData();
  }, [location, baseURL]);

  const signoutHandler = async () => {
    const changeColors = async () => {
      const colors = await axios.get("/api/colors/general");
      await updateColors(colors.data);
    };
    await changeColors();
    ctxDispatch({ type: "USER_SIGNOUT" });
    navigate("/signin");
  };

  const crearLink = async () => {
    const { data } = await axios.get(`/api/jugadores/sellLink/${userInfo._id}`);
    navigator.clipboard.writeText(data);
    toast.success("Link copiado al portapapeles");
  };

  const [expanded, setExpanded] = useState(false);

  if (route.includes("AdminScreen")) return null;

  return (
    <React.Fragment>
      <ToastContainer position="bottom-center" limit={1}></ToastContainer>
      <header>
        <Navbar
          expanded={expanded}
          className="container-fluid justify-content m-0"
          expand="lg container-fluid"
        >
          <Container fluid>
            <Navbar.Brand href="/" className="col-1  navbar-left">
              <img src={imageURL} alt="logoNav" />
            </Navbar.Brand>
            {/* <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            /> */}
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mobile-menu navbar-left nav navbar-nav col-5">
                <Nav.Link
                  href="/"
                  className="me-2 p-2"
                  onClick={() => setExpanded(false)}
                />
              </Nav>
            </Navbar.Collapse>
            <Nav fill>
              {userInfo ? (
                userInfo.isAdmin ? (
                  <>
                    <React.Fragment>
                      <Nav.Link
                        href={`/AdminScreen/subdomain/${baseURL}`}
                        className="me-2 p-2"
                        onClick={() => setExpanded(false)}
                      >
                        <i
                          className="fa fa-cog"
                          aria-hidden="true"
                          alt="Config"
                        ></i>
                      </Nav.Link>
                    </React.Fragment>
                    <NavDropdown
                      title={userInfo.nombre}
                      id="collasible-nav-dropdown"
                    >
                      <NavDropdown.Item href="/profile">
                        Mi Perfil
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/myTickets">
                        Mis Tickets
                      </NavDropdown.Item>                      
                      <NavDropdown.Divider />
                      {/* <NavDropdown.Item onClick={crearLink}>
                    <span>Crear Link de Venta</span>
                  </NavDropdown.Item> */}
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={signoutHandler}>
                        <i className="fa fa-sign-out " aria-hidden="true"></i>
                        <span>Cerrar Sesión</span>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                ) : (
                  <NavDropdown
                    title={userInfo.nombre}
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item href="/profile">
                      Mi Perfil
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/myTickets">
                        Mis Tickets
                      </NavDropdown.Item>   
                    <NavDropdown.Divider />
                    {/* <NavDropdown.Item onClick={crearLink}>
                    <span>Crear Link de Venta</span>
                  </NavDropdown.Item> */}
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={signoutHandler}>
                      <i className="fa fa-sign-out " aria-hidden="true"></i>
                      <span>Cerrar Sesión</span>
                    </NavDropdown.Item>
                  </NavDropdown>
                )
              ) : baseURL !== "" ? (
                <Nav.Link eventKey={2} href={`/subdomain/${baseURL}/signin`}>
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <span className="d-none d-lg-block">Ingresar</span>
                </Nav.Link>
              ) : null}
              {/* <Nav.Link href="/faq">
                <i className="fas fa-question-circle" aria-hidden="true" />
                <span className="d-none d-lg-block">Ayuda</span>
              </Nav.Link> */}
            </Nav>
          </Container>
        </Navbar>
      </header>
    </React.Fragment>
  );
}

export default Navbarr;
