import LoadingBox from "../../components/LoadingBox";
import React, { useContext, useReducer, useState, useEffect } from "react";
import { Store } from "../../Store";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { DateTime } from "luxon";
import "./EventScreen.css";
import PurchaseTickets from "../../components/PurchaseTicketsModal/PurchaseTickets";
import moment from "moment";
import "moment/locale/es";
import _ticketOrder from "../../services/ticket-order.service"; 

moment.locale("es");

export default function EventScreen() {
  const params = useParams();
  const { id } = params;
  const { baseURL ,ticketOrder } = params;
  const [subdomain, setSubdomain] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedTicketCategory, setSelectedTicketCategory] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const route = location.pathname;
  //#region REDUCER AND STATES
  const [{ loading, error, event, ticketCategories }, dispatch] = useReducer(
    reducer,
    {
      event: {},
      loading: true,
      error: "",
      ticketCategories: [],
    }
  );
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [key, setKey] = useState("description");
  //#endregion

  //#region REQUESTS
  const fetchData = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const result = await axios.get(`/api/events/event-data/${id}`);
      dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      const result2 = await axios.get(`/api/subdomains/baseURL/${baseURL}`);
      if (result2.data) {
        setSubdomain(result2.data);
      }
    
    } catch (error) {
      dispatch({ type: "FETCH_FAIL", payload: getError(error) });
    }

    // setAccoun/${}ts(result.data);
  };
  //#endregion
  const openPurchaseTicketModal = (category) => {
    setSelectedTicketCategory(category);
    setOpenModal(true);
  };
  const closePurchaseTicketModal = () => setOpenModal(false);


  useEffect(() => {
    
    if (userInfo?.isAdmin && !route.includes("AdminScreen")) {
      navigate(`/AdminScreen/subdomain/${baseURL}/evento/${id}`);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if(ticketOrder) {
      const searchParams = new URLSearchParams(location.search);
      const approved = searchParams.get("status") === "approved";
      if(approved) {
        approveOrder();  
      }
    }
    return () => {
      navigate(`${userInfo?.isAdmin ? '/AdminScreen' : ''}/subdomain/${baseURL}/evento/${id}`);
    }
  },[ticketOrder]);

  const approveOrder = async () => {
    try {
      await _ticketOrder.approveOrder(ticketOrder);
      await _ticketOrder.sendConfirmationEmail(ticketOrder);
      toast.success("Tu pago fue aprobado. Tus tickets fueron enviados a tu correo");
    } catch(ex) {
      console.error(ex);
    }
  }
  return (
    <div className="container" id="event-screen-container">
      <PurchaseTickets
        show={openModal}
        onBack={() => navigate("/subdomain/" + baseURL)}
        subdom={subdomain}
        close={closePurchaseTicketModal}
        ticketCategory={selectedTicketCategory}
        unableQuantity={true}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <div className="event-summary-background">
            <div className="event-summary bg-image">
              <img src={event.eventoImage?.fileLink} className="w-100" />
              <div className="mask event-image-overlay">
                <div className="event-description">
                  <h1 className="col-12">{event.nombre}</h1>
                  <h2 className="col-12">{event.lugar?.name}</h2>
                  <h2 className="col-12">
                    {event.relatedEvents.length
                      ? event.dateRange
                      : DateTime.fromISO(event.fecha).toLocaleString(
                          DateTime.DATE_SHORT
                        )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <Tab.Container id="event-tabs" defaultActiveKey="tickets">
            <Row className="section-nav-container">
              <div className="section-nav">
                <Col>
                  <Nav.Item className="nav-item-ticket-types">
                    <Nav.Link eventKey="tickets">Tickets</Nav.Link>
                  </Nav.Item>
                </Col>
                <Col>
                  <Nav.Item className="nav-item-ticket-types">
                    <Nav.Link eventKey="details">Detalles</Nav.Link>
                  </Nav.Item>
                </Col>
              </div>
            </Row>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">{event.description}</Tab.Pane>
                <Tab.Pane eventKey="tickets">
                  <div className="event-tickets row">
                  <div className="col-sm">
                      {ticketCategories.map((type) => {
                        return (
                          <div className="ticket-type row">
                            <div className="name col-sm">
                              {type.section?.name}
                              {/* <div className="numOfTickets">
                                {type.availableSeats} restantes
                              </div> */}
                            </div>
                            {/* <div className="numOfTickets"></div> */}
                            <div className="price col-sm">$ {type.price}</div>
                            
                              <div className="col-sm">
                                <Button
                                  onClick={() => openPurchaseTicketModal(type)}
                                  disabled={!type.availableSeats}
                                >
                                  Comprar
                                </Button>
                              </div>
                            
                          </div>
                        );
                      })}
                    </div>
                    <div className="venue-img-container col-sm">
                      <img
                        className="img-fluid w-100" style={{maxHeight:'100%'}}
                        src={event?.lugar?.image?.fileLink}
                      />
                    </div>
                    
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="details">
                  <div className="ticket-type container  m-5">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginRight: "10px" }}>
                        <strong>Fecha:</strong>
                      </p>
                      <p>{moment(event.fecha).format("LL")}</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginRight: "10px" }}>
                        <strong>Lugar:</strong>
                      </p>
                      <p>{event.lugar?.name}</p>
                    </div>
                    <p>{event.descripcion}</p>
                    {/* <p>
                      Desde ${precio.desde} a ${precio.hasta}
                    </p>
                    <p>Pago web</p>
                    <ul>
                      {mediosDePago.map((medio, index) => (
                        <li key={index}>{medio}</li>
                      ))} 
                    </ul>*/}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
        </>
      )}
    </div>
  );
}
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        event: action.payload.event,
        ticketCategories: action.payload.ticketCategories,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
