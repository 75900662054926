import axios from "axios";
import classnames from "classnames";
import React, { useContext, useReducer, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../App.css";
import { Store } from "../../Store";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import { getStatus } from "../../utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false, error: action.payload };
    default:
      return state;
  }
};
function AddSubdomain() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [{ loadingCreate, error }, dispatch] = useReducer(reducer, {
    loadingCreate: false,
    error: "",
  });

  const [name, setName] = useState("");
  const [baseURL, setBaseURL] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loadingPicture, setLoadingPicture] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const addSubdomainHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      await axios.post(
        "/api/subdomains",
        { name, baseURL, image },
        {
          headers: {
            Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
          },
        }
      );
      dispatch({ type: "CREATE_SUCCESS" });
      toast.success("Subdominio agregado");
      navigate("/AdminScreen/Config");
    } catch (error) {
      dispatch({ type: "CREATE_FAIL" });
      if (getStatus(error) === 401) {
        ctxDispatch({ type: "USER_SIGNOUT" });
        navigate("/signin");
        toast.error("Sesion expirada. Vuelve a ingresar.");
      } else {
        console.error(error);
        toast.error(error.message);
      }
    }
  };
  let navigate = useNavigate();

  const uploadPicture = async (event) => {
    setLoadingPicture(true);
    const file = event.target.files[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    formData.append("documentId", image);
    const multiPart = { "content-type": "multipart/form-data" };

    try {
      const response = await axios.post(
        "/api/subdomains/uploadSubdomainImg",
        formData,
        {
          ...multiPart,
          headers: {
            Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
          },
        }
      );
      setImage(response.data._id);
      setImageUrl(response.data.fileLink);
    } catch (error) {
      console.error(error);
      setImage("");
      setImageUrl(null);
    }
    setLoadingPicture(false);
  };

  return (
    <div>
      <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Subdominio</Modal.Title>
        </Modal.Header>
        <Modal.Body>Seguro desea agregar un subdominio?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={addSubdomainHandler}>Confirmar</Button>
        </Modal.Footer>
      </Modal>

      <Helmet>
        <title>Nuevo Subdominio</title>
      </Helmet>

      {loadingCreate ? (
        <Row
          className="d-flex justify-content-center align-items-center spinner-row"
          style={{ minHeight: "100vh" }}
        >
          <LoadingBox className="col-1" />
        </Row>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div
          className={classnames("container", {
            ["admin-con"]: userInfo && userInfo.isSuperAdmin,
          })}
        >
          <div className="w-100" style={{ overflow: "visible" }}>
            <Container
              fluid
              className="mt-3 mb-3"
              style={{ marginBottom: "50px" }}
            >
              <Row
                className="justify-content-center align-items-center"
                style={{ width: "100%" }}
              >
                <Col md={10} lg={8} xs={12} className="w-100">
                  <div className="borderLine"></div>
                  <Card className="shadow mb-4">
                    <Card.Body>
                      <Row>
                        <div className="mb-3 mt-md-4">
                          <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                            Nuevo Subdominio
                          </h2>
                          <div className="mb-3">
                            <Form onSubmit={(e) => e.preventDefault()}>
                              <Form.Group className="mb-3" controlid="name">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                  onChange={(e) => setName(e.target.value)}
                                ></Form.Control>
                              </Form.Group>

                              <Form.Group className="mb-3" controlid="name">
                                <Form.Label>URL</Form.Label>
                                <Form.Control
                                  onChange={(e) => setBaseURL(e.target.value)}
                                ></Form.Control>
                              </Form.Group>

                              <Form.Group className="mb-3" controlid="">
                                <Form.Label>Imagen Subdominio</Form.Label>
                                <Form.Control
                                  type="file"
                                  onChange={(e) => uploadPicture(e)}
                                  name="subdomainImg"
                                ></Form.Control>
                              </Form.Group>

                              <Form.Group className="mb-3 justify-content-center">
                                {loadingPicture ? (
                                  <Row
                                    className="d-flex justify-content-center align-items-center spinner-row"
                                    style={{ minHeight: "100vh" }}
                                  >
                                    <LoadingBox className="col-1" />
                                  </Row>
                                ) : imageUrl ? (
                                  <img
                                    alt="product_photo"
                                    src={imageUrl}
                                    style={{
                                      maxWidth: "50%",
                                      maxHeight: "30%",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="product_photo"
                                    src="/images/emptyPhoto.png"
                                    style={{
                                      maxWidth: "50%",
                                      maxHeight: "30%",
                                    }}
                                  />
                                )}
                              </Form.Group>

                              <Stack direction="horizontal" gap={10}>
                                <Button
                                  id="goBack"
                                  onClick={() => navigate(-1)}
                                >
                                  <BsArrowLeftCircle /> Atras
                                </Button>

                                <Button
                                  type="submit"
                                  className="mx-auto"
                                  onClick={handleShow}
                                >
                                  Agregar Subdominio
                                </Button>
                              </Stack>
                            </Form>
                          </div>
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddSubdomain;
