import LoadingBox from "../../components/LoadingBox";
import React, { useContext, useReducer, useState, useEffect } from "react";
import { Store } from "../../Store";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import MessageBox from "../../components/MessageBox.js";
import { FaCalendarDay, FaMapMarker } from "react-icons/fa";
import {
  BsPlusCircle,
  BsCheckCircle,
  BsXOctagon,
  BsPencilSquare,
  BsFileEarmarkRichtext,
  BsCheck2Square,
  BsCloudDownload,
  BsDownload,
} from "react-icons/bs";
import {
  Form,
  Button,
  Modal,
  Col,
  Container,
  Row,
  Card,
  OverlayTrigger,
  Tooltip,
  Stack,
  InputGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { DateTime } from "luxon";
import {
  BsArrowLeftCircle,
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsTelephone,
} from "react-icons/bs";
import bcrypt from "bcryptjs";

export default function EventoScreen() {
  const params = useParams();
  const { id } = params;
  const { baseURL } = params;
  const navigate = useNavigate();
  //#region REDUCER AND STATES
  const [{ loading, error, ticketOrders }, dispatch] = useReducer(reducer, {
    ticketOrders: [],
    loading: true,
    error: "",
  });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [key, setKey] = useState("description");

  const location = useLocation();
  const route = location.pathname;

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          `/api/ticketOrders/forUser/${userInfo._id}`
        );
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
    };
    fetchData();
  }, [userInfo]);

  async function descargarEntrada(reserva) {
    try {
      const downloadFile = await downloadFromServer(reserva);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(downloadFile.blob);
      link.download = downloadFile.fileName;
      link.click();
    } catch (ex) {
      toast.error(getError(ex));
      dispatch({ type: "FETCH_FAIL", payload: getError(ex) });
    }
  }

  const downloadFromServer = async (ticketOrder) => {
    // if (reserva.lotes.length > 1) {
    //   dispatch({ type: "FETCH_REQUEST" });
    //   const res = await axios.put(
    //     `/api/reservas/descargarBoletoReservaZip/${reserva._id}`,
    //     { admin: userInfo._id },
    //     {
    //       responseType: "arraybuffer",
    //     }
    //   );
    //   dispatch({ type: "FETCH_BOLETOS_SUCCESS" });
    //   return {
    //     blob: new Blob([res.data], { type: "application/zip" }),
    //     fileName: `Boletos.zip`,
    //   };
    // } else if (reserva.lotes.length == 1) {
    dispatch({ type: "FETCH_REQUEST" });
    const res = await axios.put(
      `/api/ticketOrders/ticketDownload/${ticketOrder._id}`,
      { admin: userInfo._id },
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      }
    );
    dispatch({ type: "FETCH_BOLETOS_SUCCESS" });
    return {
      blob: new Blob([res.data], { type: "application/pdf" }),
      fileName: `Ticket #${ticketOrder.ticket.serialNumber}.pdf`,
    };
    // }
  };

  return (
    <div className="container" id="event-screen-container">
      {loading ? (
        <LoadingBox />
      ) : (
        <div className="w-100">
          <Helmet>
            <title>Mis Tickets</title>
          </Helmet>
          <Container>
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <Row className="justify-content-center m-4 align-items-stretch">
                {ticketOrders.map((ticketOrder) => (
                  <div key={ticketOrder.id} className="col-lg-4 col-md-6 mb-4">
                    <Card className="d-flex flex-column h-100 eventCard">
                      <div className="zoom-wrapper">
                        <Card.Img
                          className="zoom-img"
                          variant="top"
                          src={ticketOrder.ticket.event.eventoImage.fileLink}
                        />
                      </div>
                      <Card.Body className="d-flex flex-column justify-content-between">
                        <Card.Subtitle className="mb-2">
                          <FaCalendarDay className="mb-2" />{" "}
                          {moment(ticketOrder.ticket.event.fecha).format("LL")}{" "}
                          - {ticketOrder.ticket.event.hora} hs
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-3">
                          <FaMapMarker className="mb-1" />{" "}
                          {ticketOrder.ticket.event.lugar.name}
                        </Card.Subtitle>
                        <Card.Title className="mx-auto text-center mb-3">
                          {ticketOrder.ticket.event.nombre}
                        </Card.Title>
                        <Card.Text className="d-flex flex-column justify-content-between">
                          <ul>
                            <li>
                              Sector:
                              {ticketOrder.ticket.ticketCategory.section.name}
                            </li>
                            <li>Valor: ${ticketOrder.ticketPrice}</li>
                            <li>
                              Fecha compra:
                              {moment(ticketOrder.orderDate).format("LLL")}
                            </li>
                          </ul>
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer className="text-muted">
                        <Button
                          md={4}
                          variant="outline-info"
                          className=" mx-1"
                          //className="btn-outline-info btn light"
                          onClick={() => descargarEntrada(ticketOrder)}
                        >
                          {/* Confirmar Pago */}
                          <BsFileEarmarkRichtext /> Descargar Entrada
                        </Button>
                      </Card.Footer>
                    </Card>
                  </div>
                ))}
              </Row>
            )}
          </Container>
        </div>
      )}
    </div>
  );
}
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, ticketOrders: action.payload, loading: false };
    case "FETCH_BOLETOS_SUCCESS":
      return { ...state, loading: false, successDelete: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
