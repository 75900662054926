import React from "react";
import { FaStore } from "react-icons/fa";
import { FaCommentDollar } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaCog } from "react-icons/fa";
import "../Screens.css";

function AdminScreen() {
  return (
    <div>
      <div className="container admin-con container-admin">
        <div className="row ">
          <div className="row">
            <div className="col-12 col-lg-3 col-admin">
              <h4>Ventas Totales</h4>
              <span>9998</span>
              <FaStore></FaStore>
            </div>
            <div className="col-12 col-lg-3 col-admin">
              <h4>Ingresos Totales</h4>
              <span>34445</span>
              <FaCommentDollar></FaCommentDollar>
            </div>
            <div className="col-12 col-lg-3 col-admin">
              <h4>Cantidad de Visitas</h4>
              <span>54444</span>
              <FaEye></FaEye>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-admin">
              <div className="row">
                {" "}
                <h3>
                  Ventas-Desempeño{" "}
                  <button className="btn-light">
                    <FaCog></FaCog>
                  </button>
                </h3>
              </div>
              <div className="row">
                <img src="/images/chart.png" alt="chart"></img>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-admin">
              <div className="row">
                {" "}
                <h3>Top Categorias</h3>
              </div>
              <div className="row">
                <img src="/images/chart.webp" alt="char2"></img>
              </div>
            </div>
            <div className="row"></div>
          </div>
        </div>
        <div className="row col-admin table-users">
          <div className="col-lg-3">
            <span>Nombre Apellido</span>
          </div>
          <div className="col-lg-1">
            <span>Ingreso</span>
          </div>
          <div className="col-lg-2">
            <span>Contacto</span>
          </div>
          <div className="col-lg-2">
            <span>Ciudad/Barrio</span>
          </div>
          <div className="col-lg-2">
            <span>
              <button className="btn btn-primary">Opciones</button>
            </span>
          </div>
          <div className="col-lg-2">
            <span>
              <button className="btn btn-info">Detalles</button>
            </span>
          </div>
        </div>
        <div className="row col-admin table-users">
          <div className="col-lg-3">
            <span>Nombre Apellido</span>
          </div>
          <div className="col-lg-1">
            <span>Ingreso</span>
          </div>
          <div className="col-lg-2">
            <span>Contacto</span>
          </div>
          <div className="col-lg-2">
            <span>Ciudad/Barrio</span>
          </div>
          <div className="col-lg-2">
            <span>
              <button className="btn btn-primary">Opciones</button>
            </span>
          </div>
          <div className="col-lg-2">
            <span>
              <button className="btn btn-info">Detalles</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminScreen;
