import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Store } from "../../Store";
import LoadingBox from "../../components/LoadingBox";
import { getError, getStatus } from "../../utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUBDOMAIN_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUBDOMAIN_SUCCESS":
      return { ...state, subdomain: action.payload, loading: false };
    case "FETCH_SUBDOMAIN_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function EditService() {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  const [{ loading, subdomain }, dispatch] = useReducer(reducer, {
    subdomain: {},
    loading: true,
    error: "",
  });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [name, setName] = useState("");
  const [baseURL, setBaseURL] = useState("");
  const [image, setImage] = useState();
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_SUBDOMAIN_REQUEST" });
      try {
        const result = await axios.get(`/api/subdomains/${id}`, {
          headers: {
            Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
          },
        });
        dispatch({ type: "FETCH_SUBDOMAIN_SUCCESS", payload: result.data });
        setName(result.data.name);
        setBaseURL(result.data.baseURL);
        setImage(result.data.image);
      } catch (error) {
        dispatch({ type: "FETCH_SUBDOMAIN_FAIL", payload: getError(error) });
        if (getStatus(error) === 401) {
          ctxDispatch({ type: "USER_SIGNOUT" });
          navigate("/signin");
          toast.error("Sesion expirada. Vuelve a ingresar.");
        } else {
          console.error(error);
          toast.error(getError(error));
        }
      }
    };
    fetchData();
  }, [id]);

  const editSubdomainHandler = async () => {
    try {
      dispatch({ type: "UPDATE_REQUEST" });
      await axios.put(
        `/api/subdomains/${subdomain._id}`,
        { name, baseURL, image },
        {
          headers: {
            Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
          },
        }
      );
      ctxDispatch({ type: "UPDATE_SUCCESS" });
      toast.success("Subdominio actualizado");
      navigate("/AdminScreen/Config");
    } catch (error) {
      dispatch({ type: "UPDATE_FAIL" });
      if (getStatus(error) === 401) {
        ctxDispatch({ type: "USER_SIGNOUT" });
        navigate("/signin");
        toast.error("Sesion expirada. Vuelve a ingresar.");
      } else {
        console.error(error);
        toast.error(getError(error));
      }
    }
  };

  const uploadPicture = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    if (image) {
      formData.append("documentId", image._id);
    }
    const multiPart = { "content-type": "multipart/form-data" };
    const response = await axios.post(
      "/api/subdomains/uploadSubdomainImg",
      formData,
      {
        ...multiPart,
        headers: {
          Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
        },
      }
    );
    setImage(response.data);
    toast.success("imagen agregada correctamente");
  };

  return (
    <div>
      {loading && <LoadingBox></LoadingBox>}
      {/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
      <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Guardar Cambios</Modal.Title>
        </Modal.Header>
        <Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={editSubdomainHandler}>Confirmar</Button>
        </Modal.Footer>
      </Modal>

      <div className="container align-items-center admin-con">
        <Helmet>
          <title> Editar Subdominio</title>
        </Helmet>
        <Container fluid>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border border-3 border-primary"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      {name}
                    </h2>
                    <div className="mb-3">
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="mb-3" controlid="title">
                          <Form.Label>Nombre:</Form.Label>
                          <Form.Control
                            type="text"
                            defaultValue={name}
                            onChange={(e) => setName(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlid="title">
                          <Form.Label>URL:</Form.Label>
                          <Form.Control
                            type="text"
                            defaultValue={baseURL}
                            onChange={(e) => setBaseURL(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlid="subdomain">
                          <Form.Label>Imagen</Form.Label>
                          <Form.Control
                            type="file"
                            name="subdomainImg"
                            onChange={uploadPicture}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                          {image && image.fileLink ? (
                            <img
                              alt="subdomain_photo"
                              src={image.fileLink}
                              style={{ maxWidth: "100%", maxHeight: "300px" }}
                            />
                          ) : (
                            <img
                              alt="subdomain_photo"
                              src="/images/emptyPhoto.png"
                              style={{ maxWidth: "700px", maxHeight: "300px" }}
                            />
                          )}
                        </Form.Group>

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button
                            type="submit"
                            className="mx-auto"
                            onClick={handleShow}
                          >
                            Guardar Cambios
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default EditService;
