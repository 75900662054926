import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Container,
  Table,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { getError } from "../../utils";
import { Store } from "../../Store";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Pagination from "../../components/Pagination";
import {
  BsPlusCircle,
  BsTrash,
  BsPencilSquare,
  BsFileEarmarkRichtext,
  BsCheck2SquareFill,
} from "react-icons/bs";
import { FaRegSnowflake } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";
import { FcOk, FcUnlock } from "react-icons/fc";
import { GiMeltingIceCube } from "react-icons/gi";
import { IoMdCheckboxOutline } from "react-icons/io";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, lotes: action.payload, loading: false };
    case "FETCH_DISP_SUCCESS":
      return { ...state, lotesDisponibles: action.payload, loading: false };
    case "FETCH_CONG_SUCCESS":
      return { ...state, lotesCongelados: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "FETCH_CANCHAS_REQUEST":
      return { ...state, loadingCanchas: true };
    case "FETCH_CANCHAS_SUCCESS":
      return { ...state, eventos: action.payload, loadingCanchas: false };
    case "FETCH_CANCHAS_FAIL":
      return { ...state, loadingCanchas: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "FREEZE_REQUEST":
      return { ...state, loading: true };
    case "FREEZE_SUCCESS":
      return { ...state, message: action.payload, loading: false };
    case "UNFREEZE_REQUEST":
      return { ...state, loading: true };
    case "UNFREEZE_SUCCESS":
      return { ...state, message: action.payload, loading: false };

    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

function LotesList() {
  const [
    {
      loading,
      error,
      lotes,
      lotesDisponibles,
      lotesCongelados,
      successDelete,
      eventos,
      loadingCanchas,
    },
    dispatch,
  ] = useReducer(reducer, {
    lotes: [],
    lotesDisponibles: [],
    lotesCongelados: [],
    eventos: [],
    loadingCanchas: true,
    loading: true,
    error: "",
  });

  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15); //maxPostsperPage
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = lotes.slice(indexOfFirstPost, indexOfLastPost);

  //pagination 2
  const [currentPageDisp, setCurrentPageDisp] = useState(1);
  const indexOfLastPostDisp = currentPageDisp * postsPerPage;
  const indexOfFirstPostDisp = indexOfLastPostDisp - postsPerPage;
  const currentPostsDisp = lotesDisponibles.slice(
    indexOfFirstPostDisp,
    indexOfLastPostDisp
  );

  //pagination 3

  const [currentPageCong, setCurrentPageCong] = useState(1);
  const indexOfLastPostCong = currentPageCong * postsPerPage;
  const indexOfFirstPostCong = indexOfLastPostCong - postsPerPage;
  const currentPostsCong = lotesCongelados.slice(
    indexOfFirstPostCong,
    indexOfLastPostCong
  );

  //change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const paginateD = (pageNumber) => setCurrentPageDisp(pageNumber);
  const paginateC = (pageNumber) => setCurrentPageCong(pageNumber);

  const [evento, setEvento] = useState("sinFiltro");

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/lotes/vendidos`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: data });

        dispatch({ type: "FETCH_REQUEST" });
        const resp = await axios.get(`/api/lotes/disponibles`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_DISP_SUCCESS", payload: resp.data });

        dispatch({ type: "FETCH_REQUEST" });
        const resp2 = await axios.get(`/api/lotes/congelados`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_CONG_SUCCESS", payload: resp2.data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    const fetchCanchas = async () => {
      try {
        dispatch({ type: "FETCH_CANCHAS_REQUEST" });
        const { data } = await axios.get(`/api/events`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_CANCHAS_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_CANCHAS_FAIL", payload: getError(err) });
      }
    };
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      fetchData();
      fetchCanchas();
    }
  }, [successDelete]);

  async function deleteLoteHandler(lote) {
    if (window.confirm("Seguro desea eliminar el Usuario?")) {
      try {
        dispatch({ type: "DELETE_REQUEST" });
        const { data } = await axios.delete(`/api/lotes/${lote._id}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "DELETE_SUCCESS" });
        toast.success("Usuario Eliminado");
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: "DELETE_FAIL" });
      }
    }
  }

  const freeze = async (lote) => {
    dispatch({ type: "FREEZE_REQUEST" });
    const { data } = await axios.put(`/api/lotes/congelarLote/${lote._id}`, {
      headers: { authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: "FREEZE_SUCCESS" });
  };

  const unfreeze = async (lote) => {
    dispatch({ type: "UNFREEZE_REQUEST" });
    const { data } = await axios.put(`/api/lotes/descongelarLote/${lote._id}`, {
      headers: { authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: "UNFREEZE_SUCCESS" });
  };

  async function editLoteHandler(lote) {
    navigate(`/AdminScreen/editLote/${lote._id}`);
  }

  async function confirmarPago(reserva) {
    navigate(`/AdminScreen/confirmarPago/${reserva._id}`);
  }

  async function newLoteHandler() {
    navigate("/AdminScreen/newLote");
  }

  async function filtrarLotes() {
    if (evento == "sinFiltro") {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/lotes`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    } else {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/lotes/filtroCancha/${evento}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    }
  }

  async function downloadExcel() {
    try {
      dispatch({ type: "DOWNLOAD_REQUEST" });
      const { data } = await axios.get(`/api/lotes/exportExcel`, {
        responseType: "arraybuffer",
        headers: { authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: "DOWNLOAD_SUCCESS" });
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `NumerosVendidos.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: "DOWNLOAD_FAIL" });
    }
  }

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <Helmet>
        <title>Lista de Lotes</title>
      </Helmet>

      <div className="container admin-con">
        <div className="row adminProdBtnRow align-items-center">
          {/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
          {/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
        </div>
        <div>
          <Container fluid>
            <Row>
              <Col md={8}>
                <h1 className="text-center p-4">
                  {/* Numeros Totales ({`${lotes[0].evento.totalLotes}`}) */}
                </h1>
              </Col>
              <Col md={2}>
                <React.Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    <Button
                      className="btn btn-dark m-1 fixed-right"
                      value="Exportar"
                      onClick={() => downloadExcel()}
                    >
                      <span>
                        <SiMicrosoftexcel /> Exportar Excel
                      </span>
                    </Button>
                  </div>
                </React.Fragment>
              </Col>
            </Row>
            {/* <Container fluid>
              <Row className="align-items-center mb-2">
                <Col md={8}>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => {
                      setCancha(e.target.value);
                    }}
                  >
                    <option value={"sinFiltro"}>Filtrar por Cancha</option>
                    {canchas.map((cancha) => {
                      return (
                        <option value={cancha._id}>
                          {cancha.nombre} : {cancha.deporte}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col md={2}>
                  <Button  onClick={() => filtrarLotes()}> Buscar</Button>
                </Col>
                <Col md={2}>
                {userInfo && userInfo.isSuperAdmin ? (
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="btn btn-dark m-1 fixed-right"
                    value="Crear Lote"
                    onClick={() => newLoteHandler()}
                  >
                    <span>
                      <BsPlusCircle /> Crear Lote
                    </span>
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
                </Col>
              </Row>
            </Container> */}

            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <>
                {/* VENDIDOS */}
                <Table responsive>
                  <ListGroup>
                    <h5 className="m-3">Numeros Vendidos ({lotes.length})</h5>
                    <ListGroupItem>
                      <Row className="align-items-center table-order">
                        {/* <Col md={2}>
                    <span>ID</span>
                  </Col> */}
                        <Col as={"th"} md={1}>
                          Numero
                        </Col>
                        {/* <Col as={"th"} md={2}>
                        Cancha
                      </Col> */}
                        <Col as={"th"} md={2}>
                          Estado
                        </Col>
                        {/* <Col as={"th"} md={1}>
                        Fila
                      </Col>
                      <Col as={"th"} md={1}>
                        Col
                      </Col> */}
                        {/* <Col as={"th"} md={1}>
                        Precio
                      </Col> */}
                        <Col as={"th"} md={1}>
                          Comprador
                        </Col>
                        <Col as={"th"} md={1}>
                          Vendedor
                        </Col>
                        {userInfo && userInfo.isSuperAdmin ? (
                          <React.Fragment>
                            <Col md={2}></Col>
                          </React.Fragment>
                        ) : null}
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                  <ListGroup>
                    {currentPosts.map((lote) => (
                      <ListGroupItem>
                        <Row
                          className="align-items-center table-order"
                          id="data"
                          key={lote._id}
                        >
                          {/* <Col md={2}>{lote._id}</Col> */}
                          <Col md={1}>{lote.nroSerial}</Col>
                          {/* <Col md={2}>
                          {lote.cancha ? lote.cancha.nombre : null}
                        </Col> */}
                          <Col md={2}>
                            {lote.estado}{" "}
                            {lote.estado === "Vendido" ? (
                              <FcOk />
                            ) : lote.estado === "Reservado" ? (
                              <FcUnlock />
                            ) : (
                              ``
                            )}
                          </Col>

                          {/* <Col md={1} className="text-center">
                          {lote.fila}
                        </Col>
                        <Col md={1} className="text-center">
                          {lote.columna}
                        </Col> */}
                          {/* <Col md={1}>{lote.precioTotal}</Col> */}
                          <Col md={1}>
                            {lote.comprador ? lote.comprador.nombre : null}
                          </Col>
                          <Col md={1}>
                            {lote.vendedor
                              ? `${lote.vendedor.nombre} (${lote.vendedor.deporte})`
                              : null}
                          </Col>

                          {userInfo && userInfo.isSuperAdmin ? (
                            <React.Fragment>
                              <Col md={2}>
                                {/* <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Confirmar Pago
                                  </Tooltip>
                                }
                              >
                                <Button
                                  md={4}
                                  variant="outline-info"
                                  className="ml-1 mx-1"
                                  disabled={
                                    lote.estado == "Reservado" ? false : true
                                  }
                                  //className="btn-outline-info btn light"
                                  onClick={() => confirmarPago(reserva)}
                                >
                               
                                  <IoMdCheckboxOutline />
                                </Button>
                              </OverlayTrigger> */}

                                {/* <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Editar</Tooltip>
                                }
                              >
                                <Button
                                  md={4}
                                  variant="outline-secondary"
                                  className="mx-2"
                                  //className="btn-outline-success btn light"
                                  onClick={() => editLoteHandler(lote)}
                                >
                                  <BsPencilSquare />
                                </Button>
                              </OverlayTrigger> */}

                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Eliminar
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    md={4}
                                    variant="outline-danger"
                                    className="mx-1"
                                    //className="btn btn-outline-danger"
                                    onClick={() => deleteLoteHandler(lote)}
                                  >
                                    <BsTrash />
                                  </Button>
                                </OverlayTrigger>
                              </Col>
                            </React.Fragment>
                          ) : null}
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Table>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={lotes.length}
                  paginate={paginate}
                ></Pagination>

                {/* DISPONIBLES */}
                <Table responsive>
                  <ListGroup>
                    <h5 className="m-3">
                      Numeros Disponibles ({lotesDisponibles.length})
                    </h5>
                    <ListGroupItem>
                      <Row className="align-items-center table-order">
                        {/* <Col md={2}>
                    <span>ID</span>
                  </Col> */}
                        <Col as={"th"} md={1}>
                          Numero
                        </Col>
                        {/* <Col as={"th"} md={2}>
                        Cancha
                      </Col> */}
                        <Col as={"th"} md={2}>
                          Estado
                        </Col>
                        {/* <Col as={"th"} md={1}>
                        Fila
                      </Col>
                      <Col as={"th"} md={1}>
                        Col
                      </Col> */}
                        {/* <Col as={"th"} md={1}>
                        Precio
                      </Col> */}
                        <Col as={"th"} md={1}>
                          Comprador
                        </Col>
                        <Col as={"th"} md={1}>
                          Vendedor
                        </Col>
                        {userInfo && userInfo.isSuperAdmin ? (
                          <React.Fragment>
                            <Col md={2}></Col>
                          </React.Fragment>
                        ) : null}
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                  <ListGroup>
                    {currentPostsDisp.map((lote) => (
                      <ListGroupItem>
                        <Row
                          className="align-items-center table-order"
                          id="data"
                          key={lote._id}
                        >
                          {/* <Col md={2}>{lote._id}</Col> */}
                          <Col md={1}>{lote.nroSerial}</Col>
                          {/* <Col md={2}>
                          {lote.cancha ? lote.cancha.nombre : null}
                        </Col> */}
                          <Col md={2}>
                            {lote.estado}{" "}
                            {lote.estado === "Vendido" ? (
                              <FcOk />
                            ) : lote.estado === "Reservado" ? (
                              <FcUnlock />
                            ) : (
                              ``
                            )}
                          </Col>

                          {/* <Col md={1} className="text-center">
                          {lote.fila}
                        </Col>
                        <Col md={1} className="text-center">
                          {lote.columna}
                        </Col> */}
                          {/* <Col md={1}>{lote.precioTotal}</Col> */}
                          <Col md={1}>
                            {lote.comprador ? lote.comprador.nombre : null}
                          </Col>
                          <Col md={1}>
                            {lote.vendedor
                              ? `${lote.vendedor.nombre} (${lote.vendedor.deporte})`
                              : null}
                          </Col>

                          {/* {userInfo && userInfo.isSuperAdmin ? ( */}
                          <React.Fragment>
                            <Col md={2}>
                              {/* <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Confirmar Pago
                                  </Tooltip>
                                }
                              >
                                <Button
                                  md={4}
                                  variant="outline-info"
                                  className="ml-1 mx-1"
                                  disabled={
                                    lote.estado == "Reservado" ? false : true
                                  }
                                  //className="btn-outline-info btn light"
                                  onClick={() => confirmarPago(reserva)}
                                >
                               
                                  <IoMdCheckboxOutline />
                                </Button>
                              </OverlayTrigger> */}

                              {/* <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Editar</Tooltip>
                                }
                              >
                                <Button
                                  md={4}
                                  variant="outline-secondary"
                                  className="mx-2"
                                  //className="btn-outline-success btn light"
                                  onClick={() => editLoteHandler(lote)}
                                >
                                  <BsPencilSquare />
                                </Button>
                              </OverlayTrigger> */}

                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Congelar</Tooltip>
                                }
                              >
                                <Button
                                  md={4}
                                  variant="outline-info"
                                  className="mx-1"
                                  //className="btn btn-outline-danger"
                                  onClick={() => freeze(lote)}
                                >
                                  <FaRegSnowflake />
                                </Button>
                              </OverlayTrigger>
                            </Col>
                          </React.Fragment>
                          {/* ) : null} */}
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Table>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={lotesDisponibles.length}
                  paginate={paginateD}
                ></Pagination>

                {/* CONGELADOS */}
                <Table responsive>
                  <ListGroup>
                    <h5 className="m-3">
                      Numeros Congelados ({lotesCongelados.length})
                    </h5>
                    <ListGroupItem>
                      <Row className="align-items-center table-order">
                        {/* <Col md={2}>
                    <span>ID</span>
                  </Col> */}
                        <Col as={"th"} md={1}>
                          Numero
                        </Col>
                        {/* <Col as={"th"} md={2}>
                        Cancha
                      </Col> */}
                        <Col as={"th"} md={2}>
                          Estado
                        </Col>
                        {/* <Col as={"th"} md={1}>
                        Fila
                      </Col>
                      <Col as={"th"} md={1}>
                        Col
                      </Col> */}
                        {/* <Col as={"th"} md={1}>
                        Precio
                      </Col> */}
                        <Col as={"th"} md={1}>
                          Comprador
                        </Col>
                        <Col as={"th"} md={1}>
                          Vendedor
                        </Col>
                        {userInfo && userInfo.isSuperAdmin ? (
                          <React.Fragment>
                            <Col md={2}></Col>
                          </React.Fragment>
                        ) : null}
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                  <ListGroup>
                    {currentPostsCong.map((lote) => (
                      <ListGroupItem>
                        <Row
                          className="align-items-center table-order"
                          id="data"
                          key={lote._id}
                        >
                          {/* <Col md={2}>{lote._id}</Col> */}
                          <Col md={1}>{lote.nroSerial}</Col>
                          {/* <Col md={2}>
                          {lote.cancha ? lote.cancha.nombre : null}
                        </Col> */}
                          <Col md={2}>
                            {lote.estado}{" "}
                            {lote.estado === "Vendido" ? (
                              <FcOk />
                            ) : lote.estado === "Reservado" ? (
                              <FcUnlock />
                            ) : (
                              ``
                            )}
                          </Col>

                          {/* <Col md={1} className="text-center">
                          {lote.fila}
                        </Col>
                        <Col md={1} className="text-center">
                          {lote.columna}
                        </Col> */}
                          {/* <Col md={1}>{lote.precioTotal}</Col> */}
                          <Col md={1}>
                            {lote.comprador ? lote.comprador.nombre : null}
                          </Col>
                          <Col md={1}>
                            {lote.vendedor
                              ? `${lote.vendedor.nombre} (${lote.vendedor.deporte})`
                              : null}
                          </Col>

                          {/* {userInfo && userInfo.isSuperAdmin ? ( */}
                          <React.Fragment>
                            <Col md={2}>
                              {/* <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Confirmar Pago
                                  </Tooltip>
                                }
                              >
                                <Button
                                  md={4}
                                  variant="outline-info"
                                  className="ml-1 mx-1"
                                  disabled={
                                    lote.estado == "Reservado" ? false : true
                                  }
                                  //className="btn-outline-info btn light"
                                  onClick={() => confirmarPago(reserva)}
                                >
                               
                                  <IoMdCheckboxOutline />
                                </Button>
                              </OverlayTrigger> */}

                              {/* <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Editar</Tooltip>
                                }
                              >
                                <Button
                                  md={4}
                                  variant="outline-secondary"
                                  className="mx-2"
                                  //className="btn-outline-success btn light"
                                  onClick={() => editLoteHandler(lote)}
                                >
                                  <BsPencilSquare />
                                </Button>
                              </OverlayTrigger> */}

                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Descongelar
                                  </Tooltip>
                                }
                              >
                                <Button
                                  md={4}
                                  variant="outline-info"
                                  className="mx-1"
                                  //className="btn btn-outline-danger"
                                  onClick={() => unfreeze(lote)}
                                >
                                  <GiMeltingIceCube />
                                </Button>
                              </OverlayTrigger>
                            </Col>
                          </React.Fragment>
                          {/* ) : null} */}
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Table>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={lotesCongelados.length}
                  paginate={paginateC}
                ></Pagination>
              </>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default LotesList;
