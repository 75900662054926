import './eventCard.css';
import Button from 'react-bootstrap/Button';
import { Card } from 'react-bootstrap';
import { FaCalendarDay,  FaMapMarker} from "react-icons/fa";
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');



function EventCard({ event, onClick}) {
  const formattedDate = moment(event.fecha).format('LL');

  return (
    <Card className="d-flex flex-column h-100 eventCard" onClick={onClick}>
      <div className="zoom-wrapper">
        <Card.Img className="zoom-img" variant="top" src={event.eventoImage?.fileLink} />
      </div>
      <Card.Body className="d-flex flex-column justify-content-between">
        <Card.Subtitle className='mb-2'><FaCalendarDay className='mb-2'/> {!event.relatedEvents.length ? `${formattedDate} - ${event.hora}` : event.dateRange}</Card.Subtitle>
        <Card.Subtitle className='mb-3'><FaMapMarker className='mb-1'/> {event.lugar.name}</Card.Subtitle>
        <Card.Title className='mx-auto text-center mb-3'>{event.nombre}</Card.Title>
        <Card.Text className="d-flex flex-column justify-content-between">
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default EventCard;