import axios from 'axios';

const BASE_URI = '/api/mercadopago';
class MercadoPagoService {
	async createPreference(orderId, baseUrl) {
		const res = await axios.post(BASE_URI, {
			orderId,
			baseUrl,
		});
		return res;
	}
	async createTransaction(orderId, prefId) {
		const res = await axios.post(`${BASE_URI}/newtransaction`, {
			order: orderId,
			collection_id: ' ',
			collection_status: 'pending',
			preference: prefId,
			payment_id: ' ',
			externalReference: ' ',
			payment_type: ' ',
			merchant_order: ' ',
		});
		return res;
	}
	async getAccessToken() {
		const res = await axios.get(`/api/config/mp/accessToken`);
		return res;
	}
	async setAccesstoken(mercadoPagoAppKey) {
		const res = await axios.put(`/api/config/mp/accessToken`, { mercadoPagoAppKey });
		return res;
	}
}
export default new MercadoPagoService();
