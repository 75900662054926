import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import SoccerFieldHorizontal from "../../components/SoccerFieldHorizontal/SoccerFieldHorizontal.js";
import RugbyFieldHorizontal from "../../components/RugbyFieldHorizontal/RugbyFieldHorizontal.js";

import {
  Form,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Container,
  Table,
  InputGroup,
  Alert,
  Stack,
  Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { getError } from "../../utils";
import { Store } from "../../Store";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Pagination from "../../components/Pagination";
import {
  BsPlusCircle,
  BsTrash,
  BsPencilSquare,
  BsFileEarmarkRichtext,
  BsCheck2SquareFill,
} from "react-icons/bs";
import { BiSearchAlt } from "react-icons/bi";
import { IoMdCheckboxOutline } from "react-icons/io";
import "./LotesCancha.css";
import LotteryTicket from "../../components/LotteryTicket/LotteryTicket";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, lotes: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

function LotesCancha() {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const [{ loading, error, lotes, successDelete }, dispatch] = useReducer(
    reducer,
    {
      lotes: [],
      loading: true,
      error: "",
    }
  );

  const { state } = useContext(Store);
  const { userInfo } = state;

  const [numRows, setNumRows] = useState(10);
  const [numCols, setNumCols] = useState(6);
  const [nombreCancha, setNombreCancha] = useState("");
  const [ticketImage, setTicketImage] = useState("");
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [selectedLots, setSelectedLots] = useState([]);
  const [availableLots, setAvailableLots] = useState([]);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15); //maxPostsperPage
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = lotes.slice(indexOfFirstPost, indexOfLastPost);
  const [numberFilter, setNumberFilter] = useState();
  //change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      fetchData();
    }
  }, [successDelete]);

  const fetchData = async () => {
    try {
      dispatch({ type: "FETCH_REQUEST" });
      const { data } = await axios.get(`/api/lotes/lotesByCancha/${id}`);
      dispatch({ type: "FETCH_SUCCESS", payload: data });
      setAvailableLots(data);
      getNombreCancha(id);
    } catch (err) {
      dispatch({ type: "FETCH_FAIL", payload: getError(err) });
    }
  };

  async function getNombreCancha(id) {
    const { data } = await axios.get(`/api/canchas/${id}`);
    setNombreCancha(data.nombre);
    if (data.ticketImage) {
      setTicketImage(data.ticketImage);
    }
  }

  async function comprarLotes() {
    if (selectedLots && selectedLots.length > 0) {
      navigate(`/reservarLotes`, { state: { lotes: selectedLots } });
    } else {
      toast.error("Debe seleccionar al menos un lote para continuar");
    }
  }

  const lotteryTicketChangeHandler = (checked) => {
    if (checked.status) {
      handleAdd(checked.serialNumber);
    } else {
      handleRemove(checked.serialNumber);
    }
  };

  const handleAdd = (serialNumber) => {
    if (selectedLots.length >= 10) {
      toast.warning("El maximo es de 10 lotes por transaccion");
      const found = lotes.find((lot) => lot.nroSerial == serialNumber);
      found.isSlected = false;
    } else {
      const found = lotes.find((lot) => lot.nroSerial == serialNumber);
      found.isSlected = true;
      setSelectedLots([...selectedLots, found]);
      setNumberFilter(null);
    }
  };

  const handleRemove = (serialNumber) => {
    const found = lotes.find((lot) => lot.nroSerial == serialNumber);
    found.isSlected = false;
    //selected
    const clearSelection = selectedLots.filter(function (lot) {
      return lot.nroSerial !== serialNumber;
    });
    setSelectedLots([...clearSelection]);
    setNumberFilter(null);
  };

  const handleRemoveSelected = (item) => {
    const found = lotes.find((lot) => lot.nroSerial == item.serialNumber);
    found.isSlected = false;
    //selected
    const clearSelection = selectedLots.filter(function (lot) {
      return lot.nroSerial !== item.serialNumber;
    });
    setSelectedLots([...clearSelection]);
    setNumberFilter(null);
  };

  const filterNumbers = (event) => {
    const query = event.target.value;
    var updatedList = [...lotes];
    updatedList = updatedList.filter((lot) => {
      return lot.nroSerial.toString().indexOf(query.toString()) !== -1;
    });
    setAvailableLots(updatedList);
  };

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <>
      <Helmet>
        <title>Lista de Lotes</title>
      </Helmet>

      <Container fluid>
        <div>
          <h1 className="m-3 p-3"> {nombreCancha}</h1>
        </div>
        <Stack gap={2} className="" style={{ height: "99vh" }}>
          {userInfo ? (
            <Stack className=" mx-1 px-1 h-auto border border-2">
              <Container fluid>
                <Row className="m-3">
                  <Col md={5}>
                    <h2>
                      <b>Numeros Seleccionados ({selectedLots.length})</b>
                    </h2>
                  </Col>
                  <Col md={{ span: 3, offset: 3 }}>
                    <Button
                      className="justify-content-left"
                      onClick={() => comprarLotes()}
                    >
                      {" "}
                      Continuar
                    </Button>
                  </Col>
                </Row>
              </Container>

              <Container className="h-100  overflow-auto">
                <Row>
                  {selectedLots.map((lot, index) =>
                    lot.estado == "Disponible" ? (
                      <Col key={`sl-${index}`} lg={2} sm={4} xs={6}>
                        <LotteryTicket
                          imageUrl={ticketImage ? ticketImage.fileLink : null}
                          serialNumber={lot.nroSerial}
                          handleChange={handleRemoveSelected}
                          isSlected={lot.isSlected}
                          showDelete={true}
                        ></LotteryTicket>
                      </Col>
                    ) : null
                  )}
                </Row>
              </Container>
            </Stack>
          ) : (
            <Alert variant="warning">
              Contactate con un vendedor para comprar tus numeros
            </Alert>
          )}
          <Stack gap={2} className="mx-1 px-1 h-75 border border-2">
            <h2 className="m-3">
              <b>Numeros Disponibles</b>
            </h2>
            <Stack direction="horizontal" gap={2}>
              <Form.Group as={Col} controlId="numeros">
                <InputGroup hasValidation>
                  <InputGroup.Text id="numeros">
                    <BiSearchAlt></BiSearchAlt>
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    aria-describedby="numeros"
                    placeholder="Ingresa el numero que deseas reservar"
                    onChange={filterNumbers}
                  ></Form.Control>
                </InputGroup>
              </Form.Group>
              {/* <Form.Label>Numero</Form.Label> */}
              {/* <Form.Control
                type="number"
                placeholder="Ingresa el numero que deseas reservar"
                onChange={filterNumbers}
              ></Form.Control> */}
            </Stack>
            <Card className="shadow h-100 overflow-auto mb-3">
              <Container className="h-100 overflow-auto">
                <Row>
                  {availableLots && availableLots.length > 0 ? (
                    availableLots.map((lot, index) =>
                      lot.estado == "Disponible" ? (
                        <Col key={`sl-${index}`} lg={2} sm={4} xs={6}>
                          <LotteryTicket
                            imageUrl={ticketImage ? ticketImage.fileLink : null}
                            serialNumber={lot.nroSerial}
                            handleChange={lotteryTicketChangeHandler}
                            isSlected={lot.isSlected}
                            showCheckStatus={true}
                          ></LotteryTicket>
                        </Col>
                      ) : null
                    )
                  ) : (
                    <p>No se encontro el numero que busca</p>
                  )}
                </Row>
              </Container>
            </Card>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

export default LotesCancha;
