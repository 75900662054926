import axios from 'axios';

const BASE_URI = '/api/utils';

class UtilsService {

	async getPaymentMethod(method) {
		const res = await axios.get(`${BASE_URI}/paymentMethods/${method}`);
		return res;
	}
	async listPaymentMethods() {
		const res = await axios.get(`${BASE_URI}/paymentmethods`);
		console.log()
		return res;
	}
	async deleteMethod(id) {
		const res = await axios.delete(`${BASE_URI}/paymentmethods/${id}`);
		return res;
	}
}
export default new UtilsService();