import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_VENUE_SUCCESS":
      return { ...state, venues: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        canc: action.payload,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};

function NewEvent() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [nombre, setNombre] = useState("");
  const [lugar, setLugar] = useState();
  const [secciones, setSecciones] = useState([]);
  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [ticketImage, setTicketImage] = useState();
  const [eventoImage, setEventoImage] = useState();
  const [validated, setValidated] = useState(false);
  const [subdoMain, setSubdoMain] = useState(); //agregar form para agregar subDomain por distintos eventos;
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [{ loading, loadingCreate, canc, venues }, dispatch] = useReducer(
    reducer,
    {
      canc: {},
      venues: [],
      loading: true,
      loadingCreate: false,
      error: "",
    }
  );

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      handleShow();
    }
    setValidated(true);
  };

  const addCanchaHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const { data } = await axios.post("/api/events/crearEvento", {
        nombre,
        lugar,
        fecha,
        hora,
        descripcion,
        ticketImage,
        eventoImage,
        subdoMain,
      });
      const ticketCategories = secciones.reduce(
        (acc, cur) => [
          ...acc,
          { event: data._id, price: cur.price, section: cur._id },
        ],
        []
      );
      await axios.post("/api/ticketCategories/mass", { ticketCategories });
      dispatch({ type: "CREATE_SUCCESS", payload: data });
      toast.success("Evento creado");
      navigate(`/AdminScreen/events`);
    } catch (ex) {
      toast.error(getError(ex));
    }
  };

  const uploadPicture = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    if (ticketImage) {
      formData.append("documentId", ticketImage._id);
    }
    formData.append("type", "ticketImage");
    const multiPart = { "content-type": "multipart/form-data" };
    const response = await axios.post(
      "/api/config/uploadSettingsImg",
      formData,
      { ...multiPart, headers: { Authorization: `Bearer ${userInfo.token}` } }
    );
    setTicketImage(response.data);
    toast.success("imagen agregada correctamente");
  };

  const uploadEventoPicture = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    let r = Math.random().toString(36).substring(7);
    formData.append("file", file, file.name.replaceAll(" ") + r);
    if (eventoImage) {
      formData.append("documentId", eventoImage._id);
    }
    formData.append("type", "canchaImage");

    const multiPart = { "content-type": "multipart/form-data" };
    const response = await axios.post(
      "/api/config/uploadSettingsImg",
      formData,
      { ...multiPart, headers: { Authorization: `Bearer ${userInfo.token}` } }
    );
    setEventoImage(response.data);
    toast.success("imagen agregada correctamente");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/venues`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_VENUE_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    fetchData();
  }, [userInfo]);

  const handleVenueChange = (venue) => {
    setLugar(venue);
    var venueItem = venues.filter(function (item) {
      return item._id === venue;
    });
    setSecciones(venueItem[0].sections);
  };

  const updateSection = (section, field, value) => {
    section[field] = value;
  };

  return (
    <div>
      <div>
        {loadingCreate && loading && <LoadingBox></LoadingBox>}
        <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Crear evento</Modal.Title>
          </Modal.Header>
          <Modal.Body>Seguro desea crear un evento?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={addCanchaHandler}>Confirmar</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Container>
        <Helmet>
          <title>Crear Evento</title>
        </Helmet>
        <Container
          fluid
          style={{ maxHeight: "90vh", overflowY: "auto", marginBottom: "3rem" }}
        >
          {" "}
          <Row className="mt-5 mb-8 vh-100 d-flex justify-content-center">
            <Col md={10} lg={8} xs={12}>
              <div className="borderLine"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Crear Evento
                    </h2>
                    <div className="mb-3">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="nombre"
                          >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              onChange={(e) => setNombre(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Col md={8}>
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label>Subir Imagen Evento</Form.Label>
                              <Form.Control
                                type="file"
                                onChange={uploadEventoPicture}
                                name="canchaImage"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            {eventoImage && eventoImage.fileLink ? (
                              <img
                                alt="logo_photo"
                                src={eventoImage.fileLink}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            ) : (
                              <img
                                alt="logo_photo"
                                src="/images/emptyPhoto.png"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md={8}>
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label>Subir Imagen Tickets</Form.Label>
                              <Form.Control
                                type="file"
                                onChange={uploadPicture}
                                name="ticketImage"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            {ticketImage && ticketImage.fileLink ? (
                              <img
                                alt="logo_photo"
                                src={ticketImage.fileLink}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            ) : (
                              <img
                                alt="logo_photo"
                                src="/images/emptyPhoto.png"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="nombre"
                          >
                            <Form.Label>Fecha</Form.Label>
                            <Form.Control
                              type="date"
                              required
                              //disabled
                              // value={cantLotes}
                              onChange={(e) => setFecha(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="nombre"
                          >
                            <Form.Label>Hora</Form.Label>
                            <Form.Control
                              type="time"
                              required
                              //disabled
                              // value={cantLotes}
                              onChange={(e) => setHora(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Row>
                            <Form.Group
                              as={Col}
                              className="mb-3"
                              controlid="nombre"
                            >
                              <Form.Label>Descripción</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={5}
                                required
                                //disabled
                                // value={cantLotes}
                                onChange={(e) => setDescripcion(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Row>
                        </Row>
                        <Row>
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlId="deporte"
                          >
                            <Form.Label>Selecciona una locación</Form.Label>
                            <Form.Select
                              value={lugar} // Set the selected value to the state variable lugar
                              onChange={(e) => {
                                handleVenueChange(e.target.value);
                              }}
                            >
                              <option key="" value="">
                                -- Selecciona uno --
                              </option>
                              {venues.map((venue, index) => (
                                <option key={index._id} value={venue._id}>
                                  {venue.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Row>
                        <br />
                        <h2 className="fw-bold mb-2 text-uppercase text-align-center ">
                          Secciones
                        </h2>
                        <br />
                        {secciones.map((section, index) => (
                          <Row key={index}>
                            <Col>
                              <Form.Group
                                as={Col}
                                className="mb-3"
                                controlId={`section-name-${index}`}
                              >
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  defaultValue={section.name}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                as={Col}
                                className="mb-3"
                                controlId={`section-price-${index}`}
                              >
                                <Form.Label>Precio</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  onChange={(e) =>
                                    updateSection(
                                      section,
                                      "price",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        ))}

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button
                            type="submit"
                            className="mx-auto"
                            //onClick={handleShow}
                          >
                            Crear evento
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default NewEvent;
