import React, { useContext, useLayoutEffect } from "react";
import axios from "axios";
import { Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { BsCalculator } from "react-icons/bs";
import { FaTruck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { Store } from "../Store.js";
import { ColorContext } from "../components/ColorContext.js";
import BootstrapCarouselComponent from "../components/Carousel";
import ShowSubdomains from "../components/ShowSubdomains.js";
import "./Screens.css";

function HomeScreen() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { updateColors } = useContext(ColorContext);

  useLayoutEffect(() => {
    const checkRedirect = async () => {
      const { data: settings } = await axios.get("/api/config/public");
      console.log(settings)
          if(settings && settings.isSingleSubdomainSite){
            navigate(
              `/subdomain${settings.subdomain.baseURL}`
            )
          }    
     }
    const setColors = async () => {
      const colors = await axios.get("/api/colors/general");
      await updateColors(colors.data);
    };
    console.log('boom')
    if (userInfo) {
      if (!userInfo.isSuperAdmin) {
        if (userInfo.isAdmin) {
          navigate(`AdminScreen/subdomain/${userInfo.subdomain.baseURL}`);
        } else {
          navigate(`/subdomain/${userInfo.subdomain.baseURL}`);
        }
      } else {
        setColors();
        navigate("/AdminScreen");
      }
    }else{
      checkRedirect();
    }
  }, [userInfo]);

  return (
    <div className="w-100">
      <Helmet></Helmet>
      <div className="w-100">
        <BootstrapCarouselComponent></BootstrapCarouselComponent>
        {/* <Container fluid className="d-grid gap-4 p-4 w-100">
          {userInfo ? (
            <Button
              className="calculatorButton"
              size="lg"
              onClick={() => {
                if (userInfo.isAdmin) {
                  navigate("/AdminScreen/newShipment");
                } else {
                  navigate("/newShipment");
                }
              }}
            >
              Nuevo envío <FaTruck />
            </Button>
          ) : (
            <Button
              className="calculatorButton"
              size="lg"
              onClick={() => navigate("/newShipment")}
            >
              Calcular envío <BsCalculator />
            </Button>
          )}
        </Container> */}
        {/* <div className="borderLine"></div>
        <div className="borderLine"></div> */}

        <Container fluid>
          <ShowSubdomains className="mt-3" />
        </Container>
      </div>
    </div>
  );
}
export default HomeScreen;
