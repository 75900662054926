import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import SoccerField from "../../components/SoccerField/SoccerField.js";
import RugbyField from "../../components/RugbyField/RugbyField.js";
import { useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Container,
  Table,
  OverlayTrigger,
  Tooltip,
  Tiles,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { getError } from "../../utils";
import { Store } from "../../Store";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Pagination from "../../components/Pagination";
import {
  BsPlusCircle,
  BsTrash,
  BsPencilSquare,
  BsFileEarmarkRichtext,
} from "react-icons/bs";
import "./Predio.css";
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loadingCanchas: true };
    case "FETCH_SUCCESS":
      return { ...state, canchas: action.payload, loadingCanchas: false };
    case "FETCH_FAIL":
      return { ...state, loadingCanchas: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

function VerPredio() {
  const [{ error, canchas, successDelete, loadingCanchas }, dispatch] =
    useReducer(reducer, {
      canchas: [],
      loadingCanchas: true,
      error: "",
    });

  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [porcentajes, setPorcentajes] = useState({});
  const [postsPerPage] = useState(15); //maxPostsperPage
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = canchas.slice(indexOfFirstPost, indexOfLastPost);
  //change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      fetchCanchas();
    }
  }, [successDelete]);

  const fetchCanchas = async () => {
    try {
      dispatch({ type: "FETCH_REQUEST" });
      const { data } = await axios.get(`/api/canchas`);
      if (data) {
        await Promise.all(
          data.map(async (element) => {
            porcentajes[element._id] = await porcentajeLotesVendidos(element);
          })
        );
      }
      dispatch({ type: "FETCH_SUCCESS", payload: data });
    } catch (err) {
      dispatch({ type: "FETCH_FAIL", payload: getError(err) });
    }
  };
  async function porcentajeLotesVendidos(cancha) {
    try {
      const response = await axios.get(
        `/api/lotes/porcentajeLotesVendidos/${cancha._id}`
      );
      return response.data;
    } catch (err) {
      dispatch({ type: "FETCH_LOTES_FAIL", payload: getError(err) });
    }
  }

  async function toLotes(index) {
    const cancha = canchas[index];
    navigate(`/lotesCancha/${cancha._id}`);
  }

  return (
    <>
      {loadingCanchas ? <LoadingBox></LoadingBox> : null}
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div>
          <Helmet>
            <title>Campo Deportivo CTM</title>
          </Helmet>
          <>
            {canchas.map((element, index) => (
              <Container fluid onClick={() => toLotes(index)}>
                <div class="container_row">
                  <div class="layer1">
                    {element.deporte == "Otros" ? (
                      <img
                        src={
                          element.canchaImage
                            ? element.canchaImage.fileLink
                            : ""
                        }
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    ) : element.deporte == "Rugby" ? (
                      <RugbyField></RugbyField>
                    ) : (
                      <SoccerField></SoccerField>
                    )}
                  </div>
                  <div class="layer2">
                    <h1>
                      {`${element.nombre}`}
                      <br />
                      {`${porcentajes[element._id].vendidos}% vendido`}
                      <br />
                      <br /> Ingresa aqui y compra tu metro
                    </h1>
                  </div>
                </div>
              </Container>
            ))}
          </>
        </div>
      )}
    </>
  );
}

export default VerPredio;
