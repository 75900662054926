import React from "react";
import './RugbyField.css';

function RugbyField() {

  return (
    <section className="pitch2">
      <div className="field2 top">
        <div className="fivemeters">
        </div>
        <div className="fifteenmeters">
        </div>
      </div>      
    <div className="field2 left2">
        <div className="ech">
          <div className="echTop"></div>
          <div className="echBottom"></div>
        </div>
        <div className="ingoal">
        </div>
        <div className="twentytwo">
        </div>
        <div className="tens">
        </div>
    </div>
    <div className="field2 right2">
        <div className="ech">
          <div className="echTop"></div>
          <div className="echBottom"></div>
        </div>
        <div className="ingoal">
        </div>
        <div className="twentytwo">
        </div>
        <div className="tens">
        </div>
    </div>  
    <div className="field2 bottom">
        <div className="fivemeters">
        </div>
        <div className="fifteenmeters">
        </div>
      </div>  
</section>
  );
}
export default RugbyField;
