import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { toast } from 'react-toastify';
export default function ConfirmDeleteMethodModal({
	deleteShow,
	handleClose,
	method,
	onHide,
}) {
	const deleteMethod = async () => {
		try {
			await axios.delete(`/api/utils/paymentmethods/${method._id}`);
			toast.success('Metodo eliminado');
			handleClose();
		} catch (ex) {
			toast.error('Error');
			console.error(ex);
		}
	};
	return (
		<Modal show={deleteShow} onHide={onHide} dialogClassName="modal-delete-pay">
			<Modal.Header closeButton>
				<Modal.Title>Borrar método</Modal.Title>
			</Modal.Header>
			<Modal.Body>
        ¿Estás seguro de que quieres eliminar este método?
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={deleteMethod}>
          Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
