import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Container,
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { getError, getStatus, maxitemsPerPage } from "../../utils";
import { Store } from "../../Store";
import LoadingBox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Pagination from "../../components/Pagination";
import {
  BsPlusCircle,
  BsTrash,
  BsPencilSquare,
  BsFileEarmarkRichtext,
} from "react-icons/bs";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        admins: action.payload,
        loading: false,
        itemQuantity: action.payload.length,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

function AdminsList() {
  const [{ loading, error, admins, successDelete, itemQuantity }, dispatch] =
    useReducer(reducer, {
      admins: [],
      loading: true,
      error: "",
      itemQuantity: 0,
    });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * maxitemsPerPage;
  const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
  let currentPosts = admins.slice(indexOfFirstPost, indexOfLastPost);
  //change page
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    currentPosts = admins.slice(indexOfFirstPost, indexOfLastPost);
  };

  //MODAL
  const [userToDelete, setUserToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/admins`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      fetchData();
    }
  }, [successDelete]);

  async function deleteUserHandler() {
    try {
      dispatch({ type: "DELETE_REQUEST" });
      await axios.delete(`/api/admins/${userToDelete._id}`, {
        headers: {
          Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
        },
      });
      dispatch({ type: "DELETE_SUCCESS" });
      handleClose();
      toast.success("Usuario Eliminado");
    } catch (error) {
      dispatch({ type: "DELETE_FAIL" });
      if (getStatus(error) === 401) {
        ctxDispatch({ type: "USER_SIGNOUT" });
        navigate("/signin");
        toast.error("Sesion expirada. Vuelve a ingresar.");
      } else {
        console.error(error);
        toast.error(getError(error));
      }
    }
  }

  async function handleDelete(user) {
    setUserToDelete(user);
    handleShow();
  }

  async function editUserHandler(admin) {
    navigate(`/AdminScreen/editAdmin/${admin._id}`);
  }

  async function detailsUserHandler(admin) {
    navigate(`/AdminScreen/admin/${admin._id}`);
  }

  async function newUserHandler() {
    navigate("/AdminScreen/newAdmin");
  }

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>Seguro desea eliminar el usuario?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={deleteUserHandler}>Confirmar</Button>
        </Modal.Footer>
      </Modal>

      <Helmet>
        <title>Lista de Administradores</title>
      </Helmet>

      <div className="container admin-con">
        <div className="row adminProdBtnRow align-items-center">
          {/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
          {/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
        </div>
        <div>
          <Container fluid className="m-3 mt-5">
            <h1 className="text-center">Lista de Administradores</h1>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <Button
                className="btn btn-dark m-1 fixed-right"
                value="Crear Usuario"
                onClick={() => newUserHandler()}
              >
                <span>
                  <BsPlusCircle /> Crear Administrador
                </span>
              </Button>
            </div>
            {loading ? (
              <LoadingBox></LoadingBox>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <Table responsive className="mx-5">
                <ListGroup>
                  <ListGroupItem>
                    <Row className="align-items-center table-order">
                      {/* <Col md={2}>
                    <span>ID</span>
                  </Col> */}
                      <Col as={"th"} md={3}>
                        <span>Nombre</span>
                      </Col>
                      <Col as={"th"} md={3}>
                        <span>e-mail</span>
                      </Col>
                      <Col as={"th"} md={3}>
                        Equipo
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
                <ListGroup>
                  {currentPosts.map((admin) => (
                    <ListGroupItem>
                      <Row
                        className="align-items-center table-order"
                        id="data"
                        key={admin._id}
                      >
                        {/* <Col md={2}>{admin._id}</Col> */}
                        <Col md={3}>{admin.nombre}</Col>
                        <Col md={3}>{admin.email}</Col>
                        <Col md={3}>
                          {admin.subdomain
                            ? admin.subdomain.name
                            : "sin asignar"}
                        </Col>
                        <Col md={2}>
                          {/* <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Ver Detalles</Tooltip>
                            }
                          >
                            <Button
                              md={4}
                              variant="outline-info"
                              className="ml-1"
                              //className="btn-outline-info btn light"
                              onClick={() => detailsUserHandler(admin)}
                            >
                              <BsFileEarmarkRichtext />
                            </Button>
                          </OverlayTrigger> */}

                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Editar</Tooltip>
                            }
                          >
                            <Button
                              md={4}
                              variant="outline-secondary"
                              className="mx-2"
                              //className="btn-outline-success btn light"
                              onClick={() => editUserHandler(admin)}
                            >
                              <BsPencilSquare />
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>Eliminar</Tooltip>
                            }
                          >
                            <Button
                              md={4}
                              variant="outline-danger"
                              className="mr-1"
                              //className="btn btn-outline-danger"
                              onClick={() => handleDelete(admin)}
                            >
                              <BsTrash />
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Table>
            )}
          </Container>
        </div>
        <Pagination
          className="pagination-bar"
          totalCount={itemQuantity}
          onPageChange={onPageChange}
          currentPage={currentPage}
          pageSize={maxitemsPerPage}
        ></Pagination>
      </div>
    </div>
  );
}

export default AdminsList;
