import React, {
  useEffect,
  useState,
} from "react";
import './LotteryTicket.css';
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from "react-icons/hi";
import { AiFillCheckCircle} from "react-icons/ai";


function LotteryTicket({imageUrl,serialNumber,handleChange,showCheckStatus,showDelete,isSlected}) {

  const [isChecked, setIsChecked] = useState(isSlected);

  useEffect(() => {        
    setIsChecked(isSlected);
}, [isSlected]);

  const handleOnChange = () => {    
    setIsChecked(!isChecked);
    handleChange({serialNumber:serialNumber,status:!isChecked});
  };
  
  return (
    <div className="first" style={{backgroundImage: `url(${imageUrl ? imageUrl : ''})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
      <p className="second" onClick={handleOnChange}>
        <span>{serialNumber}</span>
        {/* <input id="cbx" type="checkbox" checked={isChecked}/>          */}
        {showCheckStatus ? (
          !isChecked ?
            <HiOutlinePlusCircle color="green" fontSize="1.5rem" className="iconTop"/>
            :
            <AiFillCheckCircle color="green" fontSize="1.5rem"  className="iconTop"/>
        ) : showDelete ? <HiOutlineMinusCircle color="red" fontSize="1.5rem" className="iconTop"/> : null
        }              
        {/* {
          !isChecked ?
          <HiOutlinePlusCircle color="green" fontSize="1.5rem" className="iconTop"/>
          :
          <HiOutlineMinusCircle color="red" fontSize="1.5rem" className="iconTop"/>
        } */}
        
      </p>
    </div>
  );
}
export default LotteryTicket;
