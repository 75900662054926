import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Button, Container, Row, Col, Card, Stack } from "react-bootstrap";

import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftCircle } from "react-icons/bs";
import bcrypt from "bcryptjs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loadingFetch: true };
    case "FETCH_SUCCESS":
      return { ...state, reserva: action.payload, loadingFetch: false };
    case "FETCH_FAIL":
      return { ...state, loadingFetch: false, errorFetch: action.payload };
    default:
      return state;
  }
};

function ConfirmarReserva() {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [lotes, setLotes] = useState([]);
  const [precioTotal, setPrecioTotal] = useState();
  const [lotesIds, setLotesIds] = useState([]);
  const [cancha, setCancha] = useState([]);
  const [cuentas, setCuentas] = useState({});
  const [{ loadingFetch, reserva }, dispatch] = useReducer(reducer, {
    reserva: {},
    loadingFetch: true,
    error: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const { data } = await axios.get(`/api/reservas/reservaConLotes/${id}`);
        dispatch({ type: "FETCH_SUCCESS", payload: data.reserva });
        const result = data.lotes.flatMap(({ lote }) => lote.nroSerial);
        const precioTotal = data.lotes.flatMap(({ lote }) => lote.precioTotal);
        const idLotes = data.lotes.flatMap(({ lote }) => lote._id);
        const sum = precioTotal.reduce((partialSum, a) => partialSum + a, 0);
        setCancha(data.lotes[0].lote.cancha);
        setLotesIds(idLotes);
        setPrecioTotal(sum);
        setLotes(result.join("-"));
        const cuentasRes = await axios.get("/api/utils/cuentas");
        setCuentas(cuentasRes.data);
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: error.message });
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div>
        {loadingFetch && <LoadingBox></LoadingBox>}
        {/* <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Comprar Lote</Modal.Title>
          </Modal.Header>
          <Modal.Body>Seguro desea comprar Lote?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button >Confirmar</Button>
          </Modal.Footer>
        </Modal> */}
      </div>
      <Container className="large-container">
        <Helmet>
          <title>Reserva Confirmada</title>
        </Helmet>
        <Container fluid>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border border-3 border-primary"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Reserva Confirmada
                    </h2>
                    <div className="mb-3">
                      {reserva.comprador && reserva.vendedor ? (
                        <div>
                          <h5>
                            Felicitaciones <b>{reserva.comprador.nombre}</b>!
                          </h5>
                          <br></br>
                          <p>
                            Reservaste el metro perteneciente al número{" "}
                            <b>{lotes}</b> del Campus Deportivo.
                          </p>
                          <p>
                            Para confirmar la compra debes realizar el depósito
                            o transferencia bancaria.
                          </p>
                          <br />
                          <p>
                            <b>Datos de la cuenta:</b>
                          </p>
                          <p>
                            <b>Banco ITAU</b>
                          </p>
                          <p>Caja de Ahorro $</p>
                          <p>Numero: {cuentas[reserva.vendedor.deporte]}</p>
                          <p>Titular: Circulo de Tenis de Montevideo</p>
                          <br />
                          <br />

                          <p>
                            <b>
                              <u>Datos del vendedor</u>
                            </b>
                          </p>
                          <p>
                            Nombre: <b>{reserva.vendedor.nombre}</b>
                          </p>
                          <p>
                            Telefono: <b>{reserva.vendedor.telefono}</b>
                          </p>
                          <p>
                            Contáctate con el vendedor luego de realizado el
                            pago para enviarle el comprobante de transferencia o
                            depósito.
                          </p>
                          <br />

                          <p>Muchas gracias por colaborar!</p>
                        </div>
                      ) : null}

                      <Stack direction="horizontal" gap={10}>
                        <Button
                          className="border border-3"
                          onClick={() => navigate(`/`)}
                        >
                          <BsArrowLeftCircle /> Volver al Inicio
                        </Button>
                      </Stack>

                      {/* 
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="mb-3" controlid="nombre">
                          <Tooltip id={`tooltip-top`}>prueba</Tooltip>
                          <Form.Label>Hacer donación anónima</Form.Label>
                          <Form.Check
                            onChange={(e) => setAnonimo(!esAnonimo)
                            }
                          > 
                          
                          </Form.Check>
                        </Form.Group>

                        <Form.Group className="mb-3" controlid="nombre">
                          <Form.Label>Nombre del comprador:</Form.Label>
                          <Form.Control
                            type="text"
                          
                            onChange={(e) => setNombre(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlid="tel">
                          <Form.Label>Teléfono del comprador:</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            // disabled
                            onChange={(e) => setTelefono(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="lote">
                          <Form.Label>Selecciona un lote</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            disabled
                            
                            //onChange={(e) => setTelefono(e.target.value)}
                          ></Form.Control>

                       
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="jugador">
                          <Form.Label>Vendedor:</Form.Label>
                          <Form.Select
                            onChange={(e) => {
                              setVendedor(e.target.value);
                            }}
                          >
                            <option key={0}>Selecciona un jugador</option>
                            {jugadores.map((jugador) => {
                              return (
                                <option key={jugador._id} value={jugador._id}>
                                  {jugador.nombre}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlid="precio">
                          <Form.Label>Precio:</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                          
                          ></Form.Control>
                        </Form.Group>

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button
                            type="submit"
                            className="mx-auto"
                            onClick={handleShow}
                          >
                            Comprar Lote
                          </Button>
                        </Stack>
                      </Form> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default ConfirmarReserva;
