export const getError = (error) => {
  return error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
};
export const getStatus = (error) => {
  return error.response && error.response.status
    ? error.response.status
    : error.message;
};

export const regexEmail =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const whatsappBaseUrl = "https://api.whatsapp.com/send?phone=";
export const maxitemsPerPage = 10;

export const transferAccountBank = "BROU";
export const transferAccountCurrency = "Caja de Ahorro Pesos";
export const transferAccountNumber = "001315385-00001";
export const transferAccountName = "Santiago Aristoy";

export const eventId = "66a18f4a6993ac16e6ad083b";