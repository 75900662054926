import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
export default function NewPaymentMethodModal({ show, handleClose, edit, editMethod }) {
	const [method, setMethod] = useState('');
	const [active, setActive] = useState(false);
	const [details, setDetails] = useState('');
	useEffect(() => {
		if (edit) {
			setMethod(editMethod.method);
			setActive(editMethod.active);
			setDetails(editMethod.details);
		}
	}, [edit]);
	const handleNewMethod = () => {
		if (method) {
			saveNewMethod();
		} else {
			toast.error('Nombre del método es requerido.');
		}
	};
	const saveNewMethod = async () => {
		try {
			await axios.post('/api/utils/paymentmethods', {
				method: method,
				details: details,
				active: active,
			});
			toast.success('Metodo creado');
			handleClose();
		} catch (ex) {
			toast.error('Error. Intentelo de nuevo.');
			console.error(ex);
		}
	};
	const handleEditMethod = async () => {
		try {
			const data = { method, details, active };
			await axios.put(`/api/utils/paymentmethods/${editMethod._id}`, data);
			toast.success('Cambios guardados.');
			handleClose();
		} catch (ex) {
			toast.error('Error');
			console.error(ex);
		}
	};
	return (
		<Modal show={show} onHide={handleClose} dialogClassName='modal-pay'>
			<Modal.Header closeButton>
				<Modal.Title>{edit ? 'Editar metodo' : 'Nuevo metodo'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className='row justify-content-start align-items-end'>
					<Form.Group className='col-6 mb-2' controlId='newMethod'>
						<Form.Control
							onChange={(e) => setMethod(e.target.value)}
							required
							placeholder='Método'
							defaultValue={edit ? editMethod.method : ''}
						></Form.Control>
					</Form.Group>
					<Form.Group className='col-1 mb-2' controlId='newActive'>
						<Form.Check
							type='checkbox'
							label='Activo'
							onChange={(e) => setActive(e.target.checked)}
							defaultChecked={edit ? editMethod.active : false}
						/>
					</Form.Group>
					<Form.Group className='col-10' controlId='newDetails'>
						<Form.Control
							onChange={(e) => setDetails(e.target.value)}
							defaultValue={edit ? editMethod.details : ''}
							className='payment-details'
							as='textarea'
							placeholder='Detalles'
						></Form.Control>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer className='justify-content-end'>
				<Button
					className='m-0'
					variant='primary'
					onClick={editMethod ? handleEditMethod : handleNewMethod}
				>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
