import React, { useEffect, useReducer, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import './Carousel.css';
import axios from 'axios';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_BANNER_REQUEST':
		return { ...state, loadingBannerFetch: true };
	case 'FETCH_BANNER_SUCCESS':
		return {
			...state,
			banners: action.payload,
			loadingFetch: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_BANNER_FAIL':
		return {
			...state,
			loadingBannerFetch: false,
			errorBannerFetch: action.payload,
		};
	default:
		return state;
	}
};

const BootstrapCarouselComponent = () => {
	const [showComponent, setShowComponent] = useState(false);

	const [{ banners }, dispatch] = useReducer(reducer, {
		banners: [],
		loadingBannerFetch: true,
		errorBannerFetch: '',
	});

	useEffect(() => {
		if (showComponent === false) {
			const fetchData = async () => {
				try {
					dispatch({ type: 'FETCH_BANNER_REQUEST' });
					const { data } = await axios.get('/api/banners/isActiveBanner', {});
					dispatch({ type: 'FETCH_BANNER_SUCCESS', payload: data });
					setShowComponent(true);
				} catch (err) {
					dispatch({ type: 'FETCH_BANNER_FAIL', payload: err.message });
				}
			};
			fetchData();
		}
	}, []);

	return (
		<div className='w-100'>
			<div>
				<Carousel>
					{banners.map((banner) =>
						banner.image && banner.image.fileLink ? (
							<Carousel.Item key={banner._id}>
								<img className='d-block w-100 negrotest' src={banner.image.fileLink} alt={banner.title} />
								<Carousel.Caption></Carousel.Caption>
							</Carousel.Item>
						) : null,
					)}
				</Carousel>
			</div>
		</div>
	);
};

export default BootstrapCarouselComponent;
