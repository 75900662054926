import Accordion from "react-bootstrap/Accordion";
import React from 'react';

function FaqQuestion({ question, answer, eventKey }) {
  return (<Accordion.Item eventKey={eventKey} >
    <Accordion.Header >
      <h2>{question}</h2>
    </Accordion.Header>
    <Accordion.Body>
      <p>
        {answer}
      </p>
    </Accordion.Body>
  </Accordion.Item>)
}
export default FaqQuestion;
