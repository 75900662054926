import React from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";

import "../App.css";

function SubdomainCard({ title, baseURL, imgSrc }) {
  const navigate = useNavigate();

  return (
    <Card
      style={{
        width: "80%",
        height: "300px",
        maxHeight: "500px",
        backgroundColor: "#aaaeb5",
      }}
      className="d-flex align-items-center justify-content-center card-subdomain"
      onClick={() => navigate(`/subdomain/${baseURL}`)}
    >
      <div>
        <div
          style={{ maxWidth: "180px", maxHeight: "180px" }}
          className="d-flex justify-content-center"
        >
          <Card.Img
            variant="top"
            src={imgSrc}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <Card.Body style={{ maxHeight: "80px" }}>
          <Card.Title>{title}</Card.Title>
          {/* <Card.Text style={{ minHeight: "75px" }}>{baseURL}</Card.Text> */}
        </Card.Body>
      </div>
    </Card>
  );
}

export default SubdomainCard;
