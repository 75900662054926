import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  BsArrowLeftCircle,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import bcrypt from "bcryptjs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loadingFetch: true };
    case "FETCH_SUCCESS":
      return { ...state, canchas: action.payload, loadingFetch: false };
    case "FETCH_FAIL":
      return { ...state, loadingFetch: false, errorFetch: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};
function AddLote() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [numero, setNumero] = useState("");
  const [cancha, setCancha] = useState();
  const [fila, setFila] = useState();
  const [columna, setColumna] = useState();
  const [precio, setPrecio] = useState();

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [{ loadingFetch, loadingCreate, canchas }, dispatch] = useReducer(
    reducer,
    {
      canchas: [],
      loadingFetch: true,
      loadingCreate: false,
      error: "",
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get("/api/events", {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: error.message });
      }
    };
    fetchData();
  }, []);

  const addLoteHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const { data } = await axios.post("/api/lotes/crearLote", {
        numero,
        cancha,
        precio,
      });
      toast.success("Lote creado");
      navigate(`/AdminScreen/lotes`);
    } catch (ex) {
      toast.error(getError(ex));
    }
  };
  return (
    <div>
      <div>
        {loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
        <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Crear Lote</Modal.Title>
          </Modal.Header>
          <Modal.Body>Seguro desea crear lote?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={addLoteHandler}>Confirmar</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Container className="large-container">
        <Helmet>
          <title>Crear Lote</title>
        </Helmet>
        <Container fluid>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border border-3 border-primary"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Crear Lote
                    </h2>
                    <div className="mb-3">
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="mb-3" controlid="numero">
                          <Form.Label>Numero:</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            // disabled
                            onChange={(e) => setNumero(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="cancha">
                          <Form.Label>Selecciona una cancha</Form.Label>
                          <Form.Select
                            onChange={(e) => {
                              setCancha(e.target.value);
                            }}
                          >
                            <option key={0}>...</option>
                            {canchas.map((cancha) => {
                              return (
                                <option key={cancha._id} value={cancha._id}>
                                  {cancha.nombre}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                        {/*                 
                        <Form.Group className="mb-3" controlid="fila">
                          <Form.Label>Fila:</Form.Label>
                          <Form.Control
                            type="number"
                            required
                            // disabled
                            onChange={(e) => setFila(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlid="columna">
                          <Form.Label>Columna:</Form.Label>
                          <Form.Control
                            type="number"
                            required
                            // disabled
                            onChange={(e) => setColumna(e.target.value)}
                          ></Form.Control>
                        </Form.Group> */}

                        <Form.Group className="mb-3" controlid="precio">
                          <Form.Label>Precio:</Form.Label>
                          <Form.Control
                            type="number"
                            required
                            // disabled
                            onChange={(e) => setPrecio(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button
                            type="submit"
                            className="mx-auto"
                            onClick={handleShow}
                          >
                            Crear Lote
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default AddLote;
