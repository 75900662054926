import React from "react";
import "../Screens.css";
import { Card, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

function Envios() {
  return (
    <div className="container container-envios">
    <Helmet>
     <title>Shipping</title>
   </Helmet>
   {/* <Card> */}
     <Row className="envios-Row">          
         <h1 className="title-col">BASES DEL SORTEO CAMPUS CTM</h1>
         <div class="entry-content">

		<p>Circulo de Tenis de Montevideo organiza el Sorteo de un Auto Chevrolet Joy que se regirán por las presentes bases y condiciones (en adelante “las Bases”) y se desarrollará en el departamento de Montevideo, Uruguay</p>



<h2>Participación</h2>



<p>. No podrán participar personas menores de 18 años.</p>



<h2><strong>ACEPTACIÓN DE LAS BASES</strong></h2>



<p>Los participantes del sorteo aceptan de pleno derecho todas y cada una de las disposiciones descriptas en las Bases</p>



<h2><strong>PERÍODO DE LA PROMOCIÓN </strong></h2>



<p>La venta comenzara el 16 de noviembre de 2022 y los sorteo se realizarán el 22 de diciembre de 2022, el 24 de febrero de 2023 y el 24 de marzo de 2023</p>



<h2><strong>MECANISMO DE PARTICIPACIÓN</strong></h2>



<p>Para participar deberá comprar el número para el sorteo a un valor de $ 200 (pesos uruguayos doscientos).</p>


<h2><strong>PREMIOS</strong></h2>



<p>Sorteo del 22/12/22 dos viajes a Punta Cana (República Dominicana) que incluye Aereo MONTEVIDEO/PUNTA CANA/MONTEVIDEO no incluye maleta en bodega, 07 noches de alojamiento con all inclusive 4*, Traslado in/out, Tasas e impuestos (Tarifas sujetos a disponibilidad al momento de reservar,  validas para viajar del 15/04/2023 al 10/06/2023 y desde 25/09/2023 al 10/12/2023). Sorteo del 24/1/23 Una Moto Eléctica Dinamoto, modelo Tero 2. Sorteo del 24/3/23 Un Auto CHEVROLET JOY 1.0 HB 0 KM. Sorteo adicional para los que compran hasta el 20/12/22 a realizarse el 22/12/22 Una Moto Eléctca Dinamoto, modelo Mulita Smart. De no haberse vendido el número ganador, los premios se anumulan para el sorteo siguiente.  </p>



<p>Del mismo se extraerá un (1) ganador titular y 1(1) suplente para el caso en que el titular no pueda ser contactado, por causas ajenas al organizador, o no cumpla con los requisitos de asignación de premio o que no se presente a retirarlo en el plazo establecido.&nbsp;</p>



<h2><strong>GANADOR DEL SORTEO</strong></h2>



<p>los sorteos se realizarán ante Escribano Público en el Circulo de Tenís de Montevideo, Lucas Obes s/n Esq. Buschental, comunicándose el nombre del ganador en la página web del Circulo deTenís de Montevideo y en Redes Sociales. Asimismo se contactará al ganador por el mail dado al momento de la compra para coordinar la entrega del premio. Para poder acceder al premio el ganador deberá presentar su documento de identidad y el cupon de compra que recibirá por mail una vez confirmada la compra. Caducará el derecho del ganador si no concurre a retirar el premio en  90 días de realizado el sorteo. </p>



<h2><strong>Personal e intransferible</strong></h2>



<p>El premio es personal e intransferible, se entregará únicamente a la persona que resulte favorecida y que hubiera presentado con sus datos correctos y que coincidan con el registro ganador y cumpla con las condiciones detalladas. No se podrán entregar los premios a título de otra persona.</p>



<p></p>



<h2><strong>Forma de comunicación</strong></h2>



<p>El ganador será contactado a través de su perfil de Instagram, coordinando con este la mecánica para la entrega del premio.</p>



<h2><strong>Difusión</strong></h2>



<p>Esta promoción será difundida en Redes Sociales.</p>



<h2>Condiciones Generales</h2>



<p>El organizador se reserva el derecho de agregar cláusulas a estas bases o modificar las existentes para cubrir imprevistos que puedan surgir.</p>



<p>Los ganadores autorizan al Circulo de Tenís de Montevideo a difundir o publicar su nombre y/o divulgar su imagen y/o fotografía con fines publicitarios por los medios y en las formas que considere correctas, sin derecho a indemnización o compensación de ningún tipo. </p>


	</div>
     </Row>
   {/* </Card> */}
 </div>
  );
}

export default Envios;
