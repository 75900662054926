import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  BsArrowLeftCircle,
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsTelephone,
} from "react-icons/bs";
import bcrypt from "bcryptjs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loadingFetch: true };
    case "FETCH_FAIL":
      return { ...state, loadingFetch: false, errorFetch: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};
function NuevoJugador() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [nombre, setNombre] = useState();
  const [deporte, setDeporte] = useState();
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [esMenor, setMenor] = useState(false);
  const [adultoResponsable, setAdultoResponsable] = useState(null);

  const [categoria, setCategoria] = useState("");
  const [categorias, setCategorias] = useState(getCategorias(userInfo.deporte));
  const [validated, setValidated] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [{ loadingFetch, loadingCreate }, dispatch] = useReducer(reducer, {
    loadingFetch: true,
    loadingCreate: false,
    error: "",
  });

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      handleShow();
    }
    setValidated(true);
  };

  //getCategorias();

  const addJugadorHandler = async () => {
    handleClose();
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const { data } = await axios.post("/api/jugadores/crearJugador", {
        nombre,
        telefono,
        email,
        deporte: deporte ? deporte : userInfo.deporte,
        categoria,
        adultoResponsable,
        esMenor,
      });
      toast.success("Jugador creado");
      navigate(`/AdminScreen/jugadores`);
    } catch (ex) {
      toast.error(getError(ex));
    }
  };

  function getCategorias(deporte) {
    switch (deporte) {
      case "Rugby":
        return [
          "Infantiles",
          "Juveniles M13",
          "Juveniles M15",
          "Juveniles M17",
          "Mayores",
          "Femenino",
        ];
      case "Futbol":
        return [
          "Sub14",
          "Sub16",
          "Sub18 1er Año",
          "Sub18 2do Año",
          "Sub20",
          "Reserva",
          "Mayor",
          "Pre Senior",
        ];
      case "Hockey":
        return [
          "Escuela",
          "Juv. Principiantes",
          "Sub16",
          "Intermedia B",
          "Intermedia C",
          "Mami Hockey",
        ];
      default:
        return ["Administracion"];
    }
  }

  return (
    <div>
      <div>
        {loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
        <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Crear Jugador</Modal.Title>
          </Modal.Header>
          <Modal.Body>Seguro desea crear Jugador?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={addJugadorHandler}>Confirmar</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Container className="large-container">
        <Helmet>
          <title>Crear Jugador</title>
        </Helmet>
        <Container fluid>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border border-3 border-primary"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Crear Jugador
                    </h2>
                    <div className="mb-3">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md={5}
                            className="m-1"
                            controlid="esMenor"
                          >
                            <Form.Check
                              type="checkbox"
                              label="Jugador Menor de Edad"
                              onChange={(e) => setMenor(!esMenor)}
                            ></Form.Check>
                          </Form.Group>

                          {esMenor ? (
                            <React.Fragment>
                              <Form.Group
                                as={Col}
                                className="mb-3"
                                controlid="menor"
                              >
                                <Form.Label>Adulto Responsable</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  placeholder="Ingresa nombre del adulto responsable"
                                  onChange={(e) =>
                                    setAdultoResponsable(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </React.Fragment>
                          ) : null}
                        </Row>

                        <Row className="mb-3">
                          <Form.Group className="mb-3" controlid="nombre">
                            <Form.Label>Nombre del Jugador</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              onChange={(e) => setNombre(e.target.value)}
                            ></Form.Control>
                            {/* <Form.Control.Feedback type="invalid">
                            Ingresa el nombre del jugador
                          </Form.Control.Feedback> */}
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="telefono">
                            <InputGroup hasValidation>
                              <InputGroup.Text id="telefono">
                                <BsTelephone></BsTelephone>
                              </InputGroup.Text>
                              <Form.Control
                                type="telefono"
                                placeholder="Telefono"
                                aria-describedby="telefono"
                                required
                                //placeholder="Ingresa el telefono del comprador"
                                onChange={(e) => {
                                  setTelefono(e.target.value);
                                }}
                              />
                            </InputGroup>
                          </Form.Group>

                          <Form.Group as={Col} controlId="email">
                            <InputGroup hasValidation>
                              <InputGroup.Text id="email">@</InputGroup.Text>
                              <Form.Control
                                type="email"
                                placeholder="E-mail"
                                aria-describedby="email"
                                required
                                //placeholder="Ingresa el email del comprador"
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Ingresa un correo válido
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Row>
                        <br></br>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md={5}
                            className="mb-3"
                            controlId="deporte"
                          >
                            <Form.Label>Selecciona un deporte</Form.Label>
                            <Form.Select
                              type="text"
                              disabled={!userInfo.isSuperAdmin}
                              defaultValue={
                                !userInfo.isSuperAdmin
                                  ? userInfo.deporte
                                  : "Otro"
                              }
                              onChange={(e) => {
                                setDeporte(e.target.value);
                                setCategorias(getCategorias(e.target.value));
                              }}
                            >
                              <option value="Rugby">Rugby</option>
                              <option value="Futbol">Futbol</option>
                              <option value="Hockey">Hockey</option>
                              <option value="Otro">Otro</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlId="categoria"
                          >
                            <Form.Label>Categoria</Form.Label>
                            <Form.Select
                              type="text"
                              required
                              onChange={(e) => {
                                setCategoria(e.target.value);
                              }}
                            >
                              <option value={""}>
                                Selecciona categoria del jugador
                              </option>
                              {categorias.map((cat) => {
                                return <option value={cat}>{cat}</option>;
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Row>

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button
                            type="submit"
                            className="mx-auto"
                            // onClick={handleShow}
                          >
                            Crear Jugador
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default NuevoJugador;
