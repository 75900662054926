import React from 'react'
import LoadingBox from '../LoadingBox'
import './LoadingOverlay.css'

export default function LoadingOverlay() {
    return (
        <div className='loading-overlay'>
            <LoadingBox />
        </div>
    )
}
