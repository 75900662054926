import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  Tooltip,
  InputGroup,
  Stack,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import {
  BsArrowLeftCircle,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import bcrypt from "bcryptjs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";
import "react-bootstrap-typeahead/css/Typeahead.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loadingFetch: true };
    case "FETCH_SUCCESS":
      return { ...state, lote: action.payload, loadingFetch: false };
    case "FETCH_FAIL":
      return { ...state, loadingFetch: false, errorFetch: action.payload };
    case "FETCH_JUGADORES_REQUEST":
      return { ...state, loadingFetch: true };
    case "FETCH_JUGADORES_SUCCESS":
      return { ...state, jugadores: action.payload, loadingFetch: false };
    case "FETCH_JUGADORES_FAIL":
      return { ...state, loadingFetch: false, errorFetch: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};
function AddReserva() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [lotes, setLotes] = useState();
  const [precioTotal, setPrecioTotal] = useState();
  const [nombre, setNombre] = useState();
  const [esAnonimo, setAnonimo] = useState(false);
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState(null);
  const [confirmEmail, setConfirmEmail] = useState(null);
  const [cedula, setCI] = useState();
  const [validatedEmail, setValidatedEmail] = useState(true);
  const [checkValidatedEmail, setCheckValidatedEmail] = useState(true);

  const [lotesIds, setLotesIds] = useState([]);
  const [validated, setValidated] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  // const [showModal2, setShowModal2] = useState(false);
  // const handleClose2 = () => setShowModal2(false);
  // const handleShow2 = () => setShowModal2(true);

  const [{ loadingFetch, loadingCreate, lote, jugadores }, dispatch] =
    useReducer(reducer, {
      lote: {},
      jugadores: [],
      loadingJugadores: true,
      loadingFetch: true,
      loadingCreate: false,
      error: "",
    });

  useEffect(() => {
    const result = location.state.lotes.flatMap(({ nroSerial }) => nroSerial);
    const precioTotal = location.state.lotes.flatMap(
      ({ precioTotal }) => precioTotal
    );
    const idLotes = location.state.lotes.flatMap(({ _id }) => _id);
    setLotesIds(idLotes);
    const sum = precioTotal.reduce((partialSum, a) => partialSum + a, 0);
    setPrecioTotal(sum);
    setLotes(result.join("-"));
    fetchJugadores();
  }, []);

  const fetchJugadores = async () => {
    dispatch({ type: "FETCH_JUGADORES_REQUEST" });
    try {
      const result = await axios.get("/api/jugadores");
      dispatch({ type: "FETCH_JUGADORES_SUCCESS", payload: result.data });
    } catch (error) {
      dispatch({ type: "FETCH_JUGADORES_FAIL", payload: error.message });
    }
  };

  const handleSubmit = (event) => {
    setCheckValidatedEmail(confirmEmail === email);
    setValidatedEmail(confirmEmail === email);
    const form = event.currentTarget;
    if (form.checkValidity() === false || !validatedEmail) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      handleShow();
    }
    setValidated(true);
  };

  const addLoteHandler = async () => {
    try {
      handleClose();
      dispatch({ type: "CREATE_REQUEST" });
      const response = await axios.post("/api/compradores/crearComprador", {
        nombre,
        telefono,
        cedula,
        email,
        esAnonimo,
      });
      const comprador = response.data;
      crearReserva(comprador);
    } catch (ex) {
      toast.error(getError(ex));
    }
  };

  const crearReserva = async (comprador) => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const vendedor = userInfo._id;
      const { data } = await axios.post("/api/reservas/reservar", {
        comprador,
        vendedor,
        lotesIds,
      });
      // reservarLote(lote, comprador);

      navigate(`/confirmarReserva/${data.reservaBd._id}`);
    } catch (ex) {
      toast.error(getError(ex));
    }
  };

  const textoBasesYCondiciones =
    "Lorem ipsum dolor sit amet consectetur adipiscing elit, sociosqu habitant ligula enim feugiat vel parturient eu, lectus himenaeos velit rutrum mi purus. Nulla per eu velit taciti diam tempor montes molestie bibendum vulputate, nunc sagittis iaculis turpis id elementum luctus magna orci volutpat fames, risus curae mi cursus et at porttitor sed porta. Est a sociosqu fusce nulla nunc penatibus scelerisque, eget at eu urna habitant netus metus, id tellus potenti blandit consequat arcu.";

  return (
    <div>
      <div>
        {loadingCreate || loadingFetch ? <LoadingBox></LoadingBox> : null}
        <Modal size="md" show={showModal} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Comprar Lotes</Modal.Title>
          </Modal.Header>
          <Modal.Body>Seguro desea comprar Lotes?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={addLoteHandler}>Confirmar</Button>
          </Modal.Footer>
        </Modal>
        {/* <Modal size="lg" show={showModal2} onHide={handleClose2} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Bases y Condiciones</Modal.Title>
          </Modal.Header>
          <Modal.Body><p>{textoBasesYCondiciones}</p></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
      <Container className="large-container">
        <Helmet>
          <title>Comprar Lotes</title>
        </Helmet>
        <Container fluid>
          <Row className="vh-150 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border border-3 border-primary"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Comprar Lotes
                    </h2>
                    <div className="mb-3">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="nombre"
                          >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              placeholder="Ingresa nombre del comprador"
                              onChange={(e) => setNombre(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Ingresa tu nombre
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md={4}
                            className="m-1"
                            controlid="esAnonimo"
                          >
                            <Form.Check
                              type="checkbox"
                              label="Donación anónima"
                              onChange={(e) => setAnonimo(!esAnonimo)}
                            ></Form.Check>
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="email">
                            <InputGroup hasValidation>
                              <InputGroup.Text id="email">@</InputGroup.Text>
                              <Form.Control
                                type="email"
                                placeholder="E-mail"
                                aria-describedby="email"
                                required
                                //placeholder="Ingresa el email del comprador"
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setValidatedEmail(
                                    e.target.value === confirmEmail
                                  );
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Ingresa un correo válido
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="validationCustomUsername"
                          >
                            {/* <Form.Label>Email</Form.Label> */}
                            <InputGroup hasValidation>
                              <InputGroup.Text id="email">@</InputGroup.Text>
                              <Form.Control
                                type="email"
                                onChange={(e) => {
                                  setConfirmEmail(e.target.value);
                                  setValidatedEmail(e.target.value === email);
                                }}
                                placeholder="Confirma E-mail"
                                aria-describedby="email"
                                isInvalid={!checkValidatedEmail}
                                required
                                //isInvalid={confirmEmail !== email}
                              />
                              <Form.Control.Feedback type="invalid">
                                Los correos ingresados deben coincidir
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md={5}
                            className="mb-3"
                            controlid="cedula"
                          >
                            <Form.Label>Documento de Identidad</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              //placeholder="Ingresa el número de documento del comprador"
                              onChange={(e) => setCI(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Ingresa un documento
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md={7}
                            className="mb-3"
                            controlid="tel"
                          >
                            <Form.Label>Teléfono del comprador</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              // disabled
                              onChange={(e) => setTelefono(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Ingresa un teléfono de contacto
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md={8}
                            className="mb-3"
                            controlId="lote"
                          >
                            <Form.Label>Numeros</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              disabled
                              defaultValue={lotes}
                            ></Form.Control>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="precio"
                          >
                            <Form.Label>Precio Total</Form.Label>
                            <Form.Control
                              type="text"
                              disabled
                              defaultValue={precioTotal}
                            ></Form.Control>
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md={8}
                            className="m-1"
                            controlid="basesYcondiciones"
                          >
                            <Form.Check
                              type="checkbox"
                              required
                              label="Acepto Bases y Condiciones"
                            ></Form.Check>
                            <Form.Label>
                              <Link
                                className="px-2"
                                target="_blank"
                                to="/bases"
                              >
                                <small>
                                  <u>Ver Bases y Condiciones</u>
                                </small>
                              </Link>
                            </Form.Label>
                          </Form.Group>
                        </Row>

                        <Row className="m-2">
                          <p>
                            <FiAlertTriangle className="mx-2" />
                            Verifique que los datos sean correctos. Los mismos
                            serán corroborados en el caso de ganar un premio.
                          </p>
                        </Row>

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          <Button type="submit" className="mx-auto">
                            Comprar Lote
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default AddReserva;
