import axios from 'axios';

const BASE_URI = '/api/ticketOrders';

class TicketOrderService {
    async approveOrder(ticketId) {
        const res = await axios.post(`${BASE_URI}/approve-ticket/${ticketId}`);
        return res;
    }
    async getTicketOrder(ticketId) {
        const res = await axios.get(`${BASE_URI}/${ticketId}`);
        return res;
    }
    async sendConfirmationEmail(ticketId) {
        const res = await axios.put(`${BASE_URI}/singleTicketConfirmation`,{id : ticketId},
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/pdf",
            },
          }
        );
        return res;
    }
    async uploadCOmprobante(formData) {
      const res = await axios.post(BASE_URI + '/uploadComprobante', formData);
      return res;
    }
    
}
export default new TicketOrderService();
