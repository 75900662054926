import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
	Button,
	Modal,
	Form,
	Container,
	Row,
	Col,
	Card,
	InputGroup,
	Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
	BsArrowLeftCircle,
	BsFillEyeFill,
	BsFillEyeSlashFill,
} from "react-icons/bs";
import bcrypt from "bcryptjs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";

const reducer = (state, action) => {
	switch (action.type) {
		// case "FETCH_REQUEST":
		//   return { ...state, loading: true };
		// case "FETCH_SUCCESS":
		//   return { ...state, lotesTotales: action.payload, loading: false };
		// case "FETCH_FAIL":
		//   return { ...state, loading: false, error: action.payload };
		case "CREATE_REQUEST":
			return { ...state, loadingCreate: true };
		case "CREATE_SUCCESS":
			return {
				...state,
				canc: action.payload,
				loadingCreate: false,
			};
		case "CREATE_FAIL":
			return { ...state, loadingCreate: false };
		default:
			return state;
	}
};

function AddCancha() {
	const navigate = useNavigate();
	const { state } = useContext(Store);
	const { userInfo } = state;
	const [nombre, setNombre] = useState("");
	const [direccion, setDireccion] = useState("");
	const [link, setLink] = useState("");
	const [image, setImage] = useState();
	const [tribunas, setTribunas] = useState([{ nombre: '', precio: '' }]);
	const [validated,setValidated] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const [{ loading, loadingCreate, lugar }, dispatch] = useReducer(reducer, {
		lugar: {},
		loading: true,
		loadingCreate: false,
		error: "",
	});

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			event.preventDefault();
			event.stopPropagation();
			handleShow();
		}
		setValidated(true);
	};

	const handleTribunaChange = (index, key, value) => {
		const updatedTribunas = [...tribunas];
		updatedTribunas[index][key] = value;
		setTribunas(updatedTribunas);
	};

	const addTribuna = () => {
		setTribunas([...tribunas, { nombre: '', precio: '' }]);
	  };

	const addCanchaHandler = async () => {
		//const lotes = range(0, cantLotes - 1);
		try {
			dispatch({ type: "CREATE_REQUEST" });
			const { data } = await axios.post("/api/lugares/crearLugar", {
				nombre,
				direccion,
				link,
				image,
				tribunas,
			});
			dispatch({ type: "CREATE_SUCCESS", payload: data });
			toast.success("Cancha creada");
			navigate(`/AdminScreen/canchas`);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	const uploadPicture = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append("file", file, file.name.replaceAll(" ") + r);
		if (image) {
			formData.append("documentId", image._id);
		}
		formData.append("type", "image");
		const multiPart = { "content-type": "multipart/form-data" };
		const response = await axios.post(
			"/api/config/uploadSettingsImg",
			formData,
			{ ...multiPart, headers: { Authorization: `Bearer ${userInfo.token}` } }
		);
		setImage(response.data);
		toast.success("imagen agregada correctamente");
	};


	return (
		<div>
			<div>
				{loadingCreate && loading && <LoadingBox></LoadingBox>}
				<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear Lugar</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear Lugar?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addCanchaHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className="large-container">
				<Helmet>
					<title>Crear Lugar</title>
				</Helmet>
				<Container fluid>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<Col md={8} lg={6} xs={12}>
							<div className="border border-3 border-primary"></div>
							<Card className="shadow">
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<h2 className="fw-bold mb-4 text-uppercase text-align-center ">
											Crear Lugar
										</h2>
										<div className="mb-3">
											<Form
												noValidate
												validated={validated}
												onSubmit={handleSubmit}
											>
												<Row className="mb-3">
													<Form.Group
														as={Col}
														className="mb-3"
														controlid="nombre"
													>
														<Form.Label>Nombre</Form.Label>
														<Form.Control
															type="text"
															required
															onChange={(e) => setNombre(e.target.value)}
														></Form.Control>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group
														as={Col}
														className="mb-3"
														controlid="nombre"
													>
														<Form.Label>Direccion</Form.Label>
														<Form.Control
															type="text"
															required
															//disabled
															// value={cantLotes}
															onChange={(e) =>
																setDireccion(e.target.value)
															}
														></Form.Control>
													</Form.Group>
													<Form.Group
														as={Col}
														className="mb-3"
														controlid="link"
													>
														<Form.Label>Link</Form.Label>
														<Form.Control
															type="text"
															required
															//disabled
															// value={cantLotes}
															onChange={(e) =>
																setLink(e.target.value)
															}
														></Form.Control>
													</Form.Group>
												</Row>
												<Row>
													{/* <Col>
														<Form.Group className="mb-3 col-6" controlId="">
															<Form.Label>Subir Imagen</Form.Label>
															<Form.Control
																type="file"
																onChange={uploadPicture}
																name="image"
															></Form.Control>
														</Form.Group>
													</Col>
													<Col>
														{image && image.fileLink ? (
															<img
																alt="logo_photo"
																src={image.fileLink}
																style={{
																	maxWidth: "100px",
																	maxHeight: "100px",
																}}
															/>
														) : (
															<img
																alt="logo_photo"
																src="/images/emptyPhoto.png"
																style={{
																	maxWidth: "100px",
																	maxHeight: "100px",
																}}
															/>
														)}
													</Col> */}
												</Row>
												<Form.Group controlId="tribunas">
													<Form.Label>Tribunas</Form.Label>
													{tribunas.map((tribuna, index) => (
														<div key={index} className="mb-3">
															<Form.Control
																type="text"
																placeholder={`Tribuna ${index + 1} - Nombre`}
																value={tribuna.nombre}
																onChange={(e) => handleTribunaChange(index, 'nombre', e.target.value)}
																required
															/>
															<Form.Control
																type="number"
																placeholder={`Tribuna ${index + 1} - Precio`}
																value={tribuna.precio}
																onChange={(e) => handleTribunaChange(index, 'precio', e.target.value)}
																required
															/>
														</div>
													))}
													<Button variant="primary" onClick={addTribuna}>Agregar Tribuna</Button>
												</Form.Group>

												<Stack direction="horizontal" gap={10}>
													<Button id="goBack" onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button
														type="submit"
														className="mx-auto"
													//onClick={handleShow}
													>
														Crear Cancha
													</Button>
												</Stack>
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddCancha;