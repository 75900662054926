import React from "react";
import './SoccerField.css';

function SoccerField() {

  return (
    <section className="pitch">
    <div className="field left">
        <div className="penalty-area">
        </div>
    </div>
    <div className="field right">
        <div className="penalty-area">
        </div>
    </div>
    <div className="center-circle"></div>
  <div className="home-team">   
  </div>
  <div className="visitor-team">    
  </div>
</section>

// <!-- <div class="container">
// 	<div class="line"></div>
// 	<div class="half"></div>
// 	<div class="panelty left"></div>
// 	<div class="panelty right"></div>
// 	<div class="p-spot left">&nbsp;</div>
// 	<div class="p-spot right">&nbsp;</div>
// 	<div class="center"></div>
// 	<div class="p-place left"></div>
// 	<div class="p-place right"></div>	
// </div> -->
  );
}
export default SoccerField;
