import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Card,
	Dropdown,
	DropdownButton,
	Modal,
	Table,
} from 'react-bootstrap';
import { BsPencilSquare, BsPlusCircle, BsTrash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination/Pagination.jsx';
import { getError, getStatus, maxitemsPerPage } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			banners: action.payload,
			loadingFetch: false,
			itemQuantity: action.payload.length,
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AdminBanners() {
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	const [
		{ loadingFetch, errorFetch, banners, successDelete, itemQuantity },
		dispatch,
	] = useReducer(reducer, {
		banners: [],
		loadingFetch: true,
		errorFetch: '',
		itemQuantity: 0,
	});
	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastPost = currentPage * maxitemsPerPage;
	const indexOfFirstPost = indexOfLastPost - maxitemsPerPage;
	let currentPosts = banners.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
		currentPosts = banners.slice(indexOfFirstPost, indexOfLastPost);
	};
	const navigate = useNavigate();

	//MODAL
	const [bannerToDelete, setBannerToDelete] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await axios.get('/api/banners', {
					headers: {
						Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
					},
				});
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				if (getStatus(error) === 401) {
					ctxDispatch({ type: 'USER_SIGNOUT' });
					navigate('/signin');
					toast.error('Sesion expirada. Vuelve a ingresar.');
				} else {
					console.error(error);
					toast.error(error.message);
				}
			}
		};

		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function handleDelete(banner) {
		await setBannerToDelete(banner);
		handleShow();	}

	const deleteBannerHandler = async () => {
		try {
			await axios.delete(`/api/banners/${bannerToDelete._id}`, {
				headers: {
					Authorization: `Bearer ${userInfo ? userInfo.token : null}`,
				},
			});
			toast.success('Banner eliminado Correctamente');
			dispatch({ type: 'DELETE_SUCCESS' });
		} catch (error) {
			dispatch({
				type: 'DELETE_FAIL',
			});
			if (getStatus(error) === 401) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				navigate('/signin');
				toast.error('Sesion expirada. Vuelve a ingresar.');
			} else {
				console.error(error);
				toast.error(getError(error));
			}
		}
	};

	async function addBannerHandler() {
		navigate('/AdminScreen/AdminBanners/AddBanner');
	}

	async function editBannerHandler(banner) {
		navigate(`/AdminScreen/AdminBanners/EditBanner/${banner._id}`);
	}

	return (
		<div>
			{loadingFetch && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Banner</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea eliminar el banner?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
            Cancelar
					</Button>
					<Button onClick={deleteBannerHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>


			<div className="container">
				<div className="w-100">
					<div
						style={{
							display: 'flex',
							justifyContent: 'right',
							alignItems: 'center',
						}}
					>
						<Button
							className="btn btn-dark m-1 fixed-right"
							value="Add Banner"
							onClick={() => addBannerHandler()}
						>
							<span>
								<BsPlusCircle /> Agregar Banner
							</span>
						</Button>
					</div>
					{loadingFetch ? (
						<LoadingBox></LoadingBox>
					) : errorFetch ? (
						<MessageBox variant="danger">{errorFetch}</MessageBox>
					) : (
						<Card>
							<Table responsive hover size="sm">
								<thead className="align-items-center table-order">
									<th className="col-lg-4 text-left"></th>
									<th className="col-lg-4 text-left">Nombre</th>
									<th className="col-lg-2 text-center">Activo?</th>
									<th></th>
								</thead>
								{currentPosts.map((banner, i) => (
									<tbody key={i} className="align-items-center table-order">
										<tr key={banner._id}>
											<td className="col-lg-4 text-left">
												{' '}
												{banner.image && banner.image.fileLink ? (
													<img
														src={banner.image.fileLink}
														className="img-fluid rounded img-thumbnail"
													></img>
												) : (
													<img
														alt="product_photo"
														className="img-fluid rounded img-thumbnail"
														src="/images/emptyPhoto.png"
														style={{ maxWidth: '300px', maxHeight: '300px' }}
													/>
												)}
											</td>
											<td className="col-lg-4 align-middle text-left">
												{banner.name}
											</td>
											<td className="col-lg-2 align-middle text-center">
												{banner.isActive ? 'SI' : 'NO'}
											</td>
											<td className="col-lg-1  align-middle text-center">
												<DropdownButton drop="start" title="">
													<Dropdown.Item
														onClick={() => editBannerHandler(banner)}
													>
														<BsPencilSquare />
                            Editar
													</Dropdown.Item>
													<Dropdown.Item onClick={() => handleDelete(banner)}>
														<BsTrash> </BsTrash>Eliminar
													</Dropdown.Item>
												</DropdownButton>
											</td>
										</tr>
									</tbody>
								))}
							</Table>
						</Card>
					)}
				</div>

				<Pagination
					className="pagination-bar"
					totalCount={itemQuantity}
					onPageChange={onPageChange}
					currentPage={currentPage}
					pageSize={maxitemsPerPage}
				></Pagination>
			</div>
		</div>
	);
}
export default AdminBanners;
