import LoadingBox from "../../components/LoadingBox";
import React, { useContext, useReducer, useState, useEffect } from "react";
import { Store } from "../../Store";
import { Helmet } from "react-helmet-async";
import {
  Form,
  Button,
  Modal,
  Col,
  Container,
  Row,
  Card,
  Stack,
  InputGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { getError } from "../../utils";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  BsArrowLeftCircle,
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsTelephone,
} from "react-icons/bs";
import bcrypt from "bcryptjs";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, user: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function ViewJugador() {
  const params = useParams();
  const navigate = useNavigate();

  const { id } = params;
  const [{ loading, error, user }, dispatch] = useReducer(reducer, {
    user: {},
    loading: true,
    error: "",
  });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [nombre, setNombre] = useState();
  const [deporte, setDeporte] = useState();
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [esMenor, setMenor] = useState(false);
  const [adultoResponsable, setAdultoResponsable] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [validated, setValidated] = useState(false);
  const [categoria, setCategoria] = useState("");
  const [categorias, setCategorias] = useState(getCategorias(userInfo.deporte));

  function getCategorias(deporte) {
    switch (deporte) {
      case "Rugby":
        return [
          "Infantiles",
          "Juveniles M13",
          "Juveniles M15",
          "Juveniles M17",
          "Mayores",
          "Femenino",
        ];
      case "Futbol":
        return [
          "Sub14",
          "Sub16",
          "Sub18 1er Año",
          "Sub18 2do Año",
          "Sub20",
          "Reserva",
          "Mayor",
          "Pre Senior",
        ];
      case "Hockey":
        return [
          "Escuela",
          "Juv. Principiantes",
          "Sub16",
          "Intermedia B",
          "Intermedia C",
          "Mami Hockey",
        ];
      default:
        return ["Administracion"];
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(`/api/jugadores/${id}`);
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
        setNombre(result.data.nombre);
        setDeporte(result.data.deporte);
        setTelefono(result.data.telefono);
        setEmail(result.data.email);
        setMenor(result.data.esMenor);
        setAdultoResponsable(result.data.adultoResponsable);
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
      // setAccounts(result.data);
    };
    fetchData();
  }, [id]);

  return (
    <div>
      {loading && <LoadingBox></LoadingBox>}
      {/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
      {/* <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Guardar Cambios</Modal.Title>
        </Modal.Header>
        <Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={editUserHandler}>Confirmar</Button>
        </Modal.Footer>
      </Modal> */}
      <Container className="large-container">
        <Helmet>
          <title>Detalles Usuario</title>
        </Helmet>
        <Container fluid>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <div className="border border-3 border-primary"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Detalles Jugador
                    </h2>
                    <div className="mb-3">
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md={5}
                            className="m-1"
                            controlid="esMenor"
                          >
                            <Form.Check
                              type="checkbox"
                              label="Jugador Menor de Edad"
                              checked={esMenor}
                              disabled
                              onChange={(e) => setMenor(!esMenor)}
                            ></Form.Check>
                          </Form.Group>

                          {esMenor ? (
                            <React.Fragment>
                              <Form.Group
                                as={Col}
                                className="mb-3"
                                controlid="menor"
                              >
                                <Form.Label>Adulto Responsable</Form.Label>
                                <Form.Control
                                  type="text"
                                  disabled
                                  defaultValue={user.adultoResponsable}
                                  placeholder="Ingresa nombre del adulto responsable"
                                  onChange={(e) =>
                                    setAdultoResponsable(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>
                            </React.Fragment>
                          ) : null}
                        </Row>
                        <Row className="mb-3">
                          <Form.Group className="mb-3" controlid="nombre">
                            <Form.Label>Nombre del Jugador</Form.Label>
                            <Form.Control
                              type="text"
                              defaultValue={user.nombre}
                              disabled
                              onChange={(e) => setNombre(e.target.value)}
                            ></Form.Control>
                            {/* <Form.Control.Feedback type="invalid">
                            Ingresa el nombre del jugador
                          </Form.Control.Feedback> */}
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="telefono">
                            <InputGroup hasValidation>
                              <InputGroup.Text id="telefono">
                                <BsTelephone></BsTelephone>
                              </InputGroup.Text>
                              <Form.Control
                                defaultValue={user.telefono}
                                type="telefono"
                                placeholder="Telefono"
                                aria-describedby="telefono"
                                disabled
                                //placeholder="Ingresa el telefono del comprador"
                                onChange={(e) => {
                                  setTelefono(e.target.value);
                                }}
                              />
                            </InputGroup>
                          </Form.Group>

                          <Form.Group as={Col} controlId="email">
                            <InputGroup hasValidation>
                              <InputGroup.Text id="email">@</InputGroup.Text>
                              <Form.Control
                                defaultValue={user.email}
                                type="email"
                                placeholder="E-mail"
                                aria-describedby="email"
                                disabled
                                //placeholder="Ingresa el email del comprador"
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Ingresa un correo válido
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Row>
                        <br></br>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md={5}
                            className="mb-3"
                            controlId="deporte"
                          >
                            <Form.Label>Selecciona un deporte</Form.Label>
                            <Form.Select
                              type="text"
                              disabled={!userInfo.isSuperAdmin}
                              defaultValue={
                                !userInfo.isSuperAdmin
                                  ? userInfo.deporte
                                  : "Otro"
                              }
                              onChange={(e) => {
                                setDeporte(e.target.value);
                                setCategorias(getCategorias(e.target.value));
                              }}
                            >
                              <option value="Rugby">Rugby</option>
                              <option value="Futbol">Futbol</option>
                              <option value="Hockey">Hockey</option>
                              <option value="Otro">Otro</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlId="categoria"
                          >
                            <Form.Label>Categoria</Form.Label>
                            <Form.Select
                              type="text"
                              disabled
                              defaultValue={user.categoria}
                              onChange={(e) => {
                                setCategoria(e.target.value);
                              }}
                            >
                              <option value={""}>
                                Selecciona categoria del jugador
                              </option>
                              {categorias.map((cat) => {
                                return (
                                  <option
                                    value={cat}
                                    selected={
                                      user.categoria
                                        ? user.categoria == cat
                                          ? true
                                          : false
                                        : false
                                    }
                                  >
                                    {cat}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Row>

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>

                          {/* <Button
                            type="submit"
                            className="mx-auto"
                            onClick={handleShow}
                          >
                            Gardar Cambios
                          </Button> */}
                        </Stack>
                        {/* <div className="mb-3">
                          
                       
                        </div> */}
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default ViewJugador;
