import React, { useContext, useEffect, useState } from "react";
import "./ConfirmationPurchaseModal.css";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { BsCheckLg, BsFileEarmarkRichtext } from "react-icons/bs";
import { FaDownload } from "react-icons/fa6";

import Col from "react-bootstrap/Col";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
import { Store } from "../../Store";
import _mercadopago from "../../services/mercadopago.service";

export default function ConfirmationPurchaseModal({
  show,
  ticketOrder,
  subdom,
  close,
  onBack,
}) {
  const [src, setSrc] = useState("");
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const [quantity, setQuantity] = useState(0);
  const [preference, setPreference] = useState(null);
  const location = useLocation();
  // async function descargarEntrada(reserva) {
  //   try {
  //     const downloadFile = await downloadFromServer(reserva);
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(downloadFile.blob);
  //     link.download = downloadFile.fileName;
  //     link.click();
  //   } catch (ex) {
  //     toast.error(getError(ex));
  //     dispatch({ type: "FETCH_FAIL", payload: getError(ex) });
  //   }
  // }

  // const downloadFromServer = async (ticketOrder) => {
  //   // if (reserva.lotes.length > 1) {
  //   //   dispatch({ type: "FETCH_REQUEST" });
  //   //   const res = await axios.put(
  //   //     `/api/reservas/descargarBoletoReservaZip/${reserva._id}`,
  //   //     { admin: userInfo._id },
  //   //     {
  //   //       responseType: "arraybuffer",
  //   //     }
  //   //   );
  //   //   dispatch({ type: "FETCH_BOLETOS_SUCCESS" });
  //   //   return {
  //   //     blob: new Blob([res.data], { type: "application/zip" }),
  //   //     fileName: `Boletos.zip`,
  //   //   };
  //   // } else if (reserva.lotes.length == 1) {
  //   dispatch({ type: "FETCH_REQUEST" });
  //   const res = await axios.put(
  //     `/api/ticketOrders/ticketDownload/${ticketOrder._id}`,
  //     { admin: userInfo._id },
  //     {
  //       responseType: "arraybuffer",
  //       headers: {
  //         Accept: "application/pdf",
  //       },
  //     }
  //   );
  //   dispatch({ type: "FETCH_BOLETOS_SUCCESS" });
  //   return {
  //     blob: new Blob([res.data], { type: "application/pdf" }),
  //     fileName: `Ticket #${ticketOrder.ticket.serialNumber}.pdf`,
  //   };
  //   // }
  // };
  useEffect(() => {
    if(ticketOrder?.paymentMethod?.toLowerCase() === "mercadopago"){
      const fetchPreference = async () => {
        try {
          const {data: preference} = await _mercadopago
          .createPreference(ticketOrder._id,
            `${window.location.origin}${location.pathname}`);
          setPreference(preference);
        } catch (ex) {
          console.error(ex);
        }
      };
      fetchPreference();
    }
  },[ticketOrder]);

  const closeModal = async () => {
    close();
  };

  const handleMpCheckout = async () => {
    const checkout = preference.init_point;
    try {
      await _mercadopago.createTransaction(ticketOrder, preference.id);
      window.location.href = checkout;
    } catch (ex) {
      console.error(ex);
    }
};

  return (
    <>
      <div className="text-center">
        {/* Modal */}
        <Modal
          id="myModal"
          //className="modal fade"
          show={show}
          close={close}
          centered
        >
          {/* Modal Content */}
          <div>
            <Modal.Header className="modal-header">
              <div className="icon-box">
                <i className="material-icons">
                  <BsCheckLg style={{ display: "block", margin: "auto" }} />
                </i>
              </div>
              <Modal.Title className="modal-title">
                Gracias por tu compra!
              </Modal.Title>
            </Modal.Header>

            {/* Modal Body */}
            <Modal.Body className="justify-content-center">
              <p className="text-center">
                Te enviaremos un correo con la entrada en cuanto este confirmado el pago.
              </p>
              {/* <Button
                className="d-flex"
                onClick={() => descargarEntrada(ticketOrder)}
              >
                {" "}
                <FaDownload style={{ marginRight: "10px" }} /> Entradas
              </Button> */}
            </Modal.Body>

            {/* Modal Footer */}
            <Modal.Footer className="modal-footer">
              {ticketOrder?.paymentMethod?.toLowerCase() !== "mercadopago" && <button
                className="btn btn-success btn-block"
                data-dismiss="modal"
                onClick={() => closeModal()}
              >
                OK
              </button>}
            </Modal.Footer>
          </div>
          {/* </div> */}
        </Modal>
      </div>
    </>
    // </div>
  );
}
