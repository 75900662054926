import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import {
  FaUsers,
  FaTheaterMasks,
  FaAddressCard,
  FaHome,
  FaCog,
} from "react-icons/fa";
import { IoTicket } from "react-icons/io5";
import { MdStadium } from "react-icons/md";

import NavDropdown from "react-bootstrap/NavDropdown";
import React, {
  useContext,
  useState,
  useReducer,
  useLayoutEffect,
} from "react";
import axios from "axios";
import { Store } from "../../Store";
import { LinkContainer } from "react-router-bootstrap";
import { ColorContext } from "../ColorContext";
import "../../App.css";
import "./AdminNavbar.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BsGearFill } from "react-icons/bs";
import { GrBundle } from "react-icons/gr";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_COMPANY_REQUEST":
      return { ...state, loading: true };
    case "FETCH_COMPANY_SUCCESS":
      return { ...state, GotaSettings: action.payload, loading: false };
    case "FETCH_COMPANY_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function AdminNavbar() {
  const [{ loading, GotaSettings }, dispatch] = useReducer(reducer, {
    GotaSettings: {},
    loading: true,
    error: "",
  });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [imageURL, setImageURL] = useState();
  const { updateColors } = useContext(ColorContext);

  useLayoutEffect(() => {
    //DATA DEPENDIENDO DE USUARIO
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_COMPANY_REQUEST" });
        const { data } = await axios.get(`/api/config/public`);
        if (userInfo.isSuperAdmin) {
          setImageURL(data.image.fileLink);
        } else if (userInfo.isAdmin) {
          setImageURL(userInfo.subdomain.image.fileLink);
        }
        dispatch({ type: "FETCH_COMPANY_SUCCESS", payload: data });
      } catch (err) {
        dispatch({ type: "FETCH_COMPANY_FAIL", payload: err.message });
      }
    };
    fetchData();
  }, [userInfo]);

  const signoutHandler = async () => {
    const changeColors = async () => {
      const colors = await axios.get("/api/colors/general");
      await updateColors(colors.data);
    };
    await changeColors();

    ctxDispatch({ type: "USER_SIGNOUT" });
    navigate("/");
  };

  const [isActiveCollapse, setActiveCollapse] = useState(true);

  const handleToggleCollapse = () => {
    setActiveCollapse(!isActiveCollapse);
  };
  const Collapse = () => {
    setActiveCollapse(true);
  };

  const location = useLocation();
  const route = location.pathname;
  if (!route.includes("AdminScreen")) return null;

  return (
    <div>
      <ToastContainer position="bottom-center" limit={1}></ToastContainer>
      <header className="header-admin">
        <Navbar className="container-fluid admin-navbar" expand="d-none ">
          <Navbar.Toggle
            onClick={handleToggleCollapse}
            aria-controls="basic-navbar-nav"
            className="d-block admin-toggler"
          />

          <Navbar.Brand className="col-1  navbar-left">
            <Link
              className=""
              to="/"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={imageURL}
                alt="Company logo"
                style={{
                  maxWidth: "80%",
                  height: "auto",
                  maxHeight: "80px",
                  alignSelf: "center",
                  minWidth: "60px",
                  margin: 0,
                }}
              />
            </Link>
          </Navbar.Brand>

          <Navbar
            className={
              isActiveCollapse
                ? "navbar-collapse-Admin  "
                : "navbar-collapse-Admin open "
            }
            id="basic-navbar-nav"
          >
            <ul className="mobile-menu-Admin">
              {/* <li>
                <Link className="nav-link" to="/AdminScreen" onClick={Collapse}>
                  <FaChartBar />
                  <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                    Admin Inicio
                  </h3>
                </Link>
              </li>               */}

              {userInfo && userInfo.isSuperAdmin ? (
                <React.Fragment>
                  <li>
                    <Link
                      className="nav-link"
                      to="/AdminScreen/admins"
                      onClick={Collapse}
                    >
                      <FaUsers />
                      <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                        Administradores
                      </h3>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      to="/AdminScreen/bundles"
                      onClick={Collapse}
                    >
                      <GrBundle />
                      <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                        Cuponeras
                      </h3>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      to="/AdminScreen/venues"
                      onClick={Collapse}
                    >
                      <MdStadium />
                      <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                        Lugares
                      </h3>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link"
                      to="/AdminScreen/events"
                      onClick={Collapse}
                    >
                      <FaTheaterMasks />
                      <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                        Eventos
                      </h3>
                    </Link>
                  </li>
                </React.Fragment>
              ) : null}

              <li>
                <Link
                  className="nav-link"
                  to="/AdminScreen/usuarios"
                  onClick={Collapse}
                >
                  <FaAddressCard />
                  <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                    Usuarios
                  </h3>
                </Link>
              </li>

              {/* <li>
                <Link
                  className="nav-link"
                  to="/AdminScreen/lotes"
                  onClick={Collapse}
                >
                  <MdConfirmationNumber />
                  <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                    Ventas
                  </h3>
                </Link>
              </li> */}

              <li>
                <Link
                  className="nav-link"
                  to="/AdminScreen/ventas/search"
                  onClick={Collapse}
                >
                  <IoTicket />
                  <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                    Ventas
                  </h3>
                </Link>
              </li>

              <li>
                {" "}
                <Link className="nav-link" to="/" onClick={Collapse}>
                  <FaHome />
                  <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                    Inicio
                  </h3>
                </Link>
              </li>

              <li>
                <Link
                  className="nav-link"
                  to="/AdminScreen/Config"
                  onClick={Collapse}
                >
                  <FaCog />
                  <h3 className={isActiveCollapse ? "d-none" : "showw"}>
                    Setup
                  </h3>
                </Link>
              </li>
            </ul>
          </Navbar>

          <div className="admin-navbar-right">
            {userInfo ? (
              <NavDropdown title={userInfo.nombre} id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item className="navBarlinks">
                    Mi Perfil
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/myTickets">
                  <NavDropdown.Item className="navBarlinks">
                    Mis Tickets
                  </NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
                <li className="rightlinks">
                  {" "}
                  <div
                    className="dropdown-item"
                    // to="#signout"
                    onClick={signoutHandler}
                  >
                    <i className="fa fa-sign-out " aria-hidden="true"></i>
                    <span className="navBarlinks">Salir</span>
                  </div>
                </li>
              </NavDropdown>
            ) : (
              <li className="rightlinks">
                {" "}
                <Link className="nav-link" to="/signin">
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <span className="d-none d-lg-block navBarlinks">
                    Ingresar
                  </span>
                </Link>
              </li>
            )}
          </div>
        </Navbar>
      </header>
      <div></div>
    </div>
  );
}

export default AdminNavbar;
