import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  Stack,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftCircle } from "react-icons/bs";
import { Store } from "../../Store";
import { getError } from "../../utils";
import LoadingBox from "../../components/LoadingBox";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, event: action.payload, loading: true };
    case "FETCH_VENUE_SUCCESS":
      return { ...state, venues: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        canc: action.payload,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };
    default:
      return state;
  }
};

function ViewEvent() {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [nombre, setNombre] = useState("");
  const [lugar, setLugar] = useState();
  const [secciones, setSecciones] = useState([]);
  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [ticketImage, setTicketImage] = useState();
  const [eventoImage, setEventoImage] = useState();
  const [validated, setValidated] = useState(false);
  const [subdoMain, setSubdoMain] = useState(); //agregar form para agregar subDomain por distintos eventos;
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [{ loading, loadingCreate, event, venues }, dispatch] = useReducer(
    reducer,
    {
      event: {},
      venues: [],
      loading: true,
      loadingCreate: false,
      error: "",
    }
  );

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      handleShow();
    }
    setValidated(true);
  };

  const addCanchaHandler = async () => {
    try {
      dispatch({ type: "CREATE_REQUEST" });
      const { data } = await axios.post("/api/events/crearEvento", {
        nombre,
        lugar,
        fecha,
        hora,
        descripcion,
        ticketImage,
        eventoImage,
        subdoMain,
      });
      const ticketCategories = secciones.reduce(
        (acc, cur) => [
          ...acc,
          {
            event: data._id,
            price: cur.price,
            section: cur._id,
            availableSeats: cur.seats,
          },
        ],
        []
      );
      await axios.post("/api/ticketCategories/mass", { ticketCategories });
      dispatch({ type: "CREATE_SUCCESS", payload: data });
      toast.success("Evento creado");
      navigate(`/AdminScreen/events`);
    } catch (ex) {
      toast.error(getError(ex));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axios.get(`/api/venues`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: "FETCH_VENUE_SUCCESS", payload: data });
        dispatch({ type: "FETCH_REQUEST" });
        const result = await axios.get(`/api/events/${id}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        setNombre(result.data.nombre);
        setLugar(result.data.lugar);
        setFecha(result.data.fecha);
        setHora(result.data.hora);
        setDescripcion(result.data.descripcion);
        setTicketImage(result.data.ticketImage);
        setEventoImage(result.data.eventoImage);
        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
        const result2 = await axios.get(
          `/api/ticketCategories/forEvent/${id}`,
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );

        setSecciones(result2.data);
        
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    fetchData();
  }, [userInfo, id]);

  const updateSection = (section, field, value) => {
    section[field] = value;
  };

  return (
    <div>
      <div>{loadingCreate && loading && <LoadingBox></LoadingBox>}</div>
      <Container
        fluid
        style={{ maxHeight: "90vh", overflowY: "auto", marginBottom: "4rem" }}
      >
        <Helmet>
          <title>Detalles del Evento</title>
        </Helmet>
        <Container>
          <Row className="mt-5 mb-8 vh-100 d-flex justify-content-center">
            <Col md={10} lg={8} xs={12}>
              <div className="borderLine"></div>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <h2 className="fw-bold mb-4 text-uppercase text-align-center ">
                      Detalles del Evento
                    </h2>
                    <div className="mb-3">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="nombre"
                          >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              defaultValue={nombre}
                              disabled
                              onChange={(e) => setNombre(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Col
                            md={6}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <div className="text-center">
                              <Row>
                                <Form.Label>Imagen Evento</Form.Label>
                              </Row>
                              <Row>
                                {eventoImage && eventoImage.fileLink ? (
                                  <img
                                    alt="logo_photo"
                                    src={eventoImage.fileLink}
                                    style={{
                                      maxWidth: "220px",
                                      maxHeight: "220px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="logo_photo"
                                    src="/images/emptyPhoto.png"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "220px",
                                    }}
                                  />
                                )}
                              </Row>
                            </div>
                          </Col>
                          <Col
                            md={6}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <div className="text-center">
                              <Row>
                                <Form.Label>Imagen Tickets</Form.Label>
                              </Row>
                              <Row>
                                {ticketImage && ticketImage.fileLink ? (
                                  <img
                                    alt="logo_photo"
                                    src={ticketImage.fileLink}
                                    style={{
                                      maxWidth: "220px",
                                      maxHeight: "220px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="logo_photo"
                                    src="/images/emptyPhoto.png"
                                    style={{
                                      maxWidth: "220px",
                                      maxHeight: "220px",
                                    }}
                                  />
                                )}
                              </Row>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="nombre"
                          >
                            <Form.Label>Fecha</Form.Label>
                            <Form.Control
                              type="date"
                              required
                              defaultValue={fecha.substring(0, 10)}
                              disabled
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            className="mb-3"
                            controlid="nombre"
                          >
                            <Form.Label>Hora</Form.Label>
                            <Form.Control
                              type="time"
                              required
                              //disabled
                              // value={cantLotes}
                              defaultValue={hora}
                              disabled
                            ></Form.Control>
                          </Form.Group>
                          <Row>
                            <Form.Group
                              as={Col}
                              className="mb-3"
                              controlid="nombre"
                            >
                              <Form.Label>Descripción</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={5}
                                required
                                disabled
                                defaultValue={descripcion}
                              ></Form.Control>
                            </Form.Group>
                          </Row>
                        </Row>

                        <br />
                        <h2 className="fw-bold mb-2 text-uppercase text-align-center ">
                          Secciones
                        </h2>
                        <br />
                        {secciones.map((section) => {
                          return (
                            <Row>
                              <Col>
                                <Form.Group
                                  as={Col}
                                  className="mb-3"
                                  controlid="name"
                                >
                                  <Form.Label>Nombre</Form.Label>
                                  <Form.Control
                                    type="text"
                                    required
                                    defaultValue={section.section.name}
                                    disabled
                                  ></Form.Control>
                                </Form.Group>
                              </Col>
                              {/* <Col>
                              <Form.Group
                                as={Col}
                                className="mb-3"
                                controlid="name"
                              >
                                <Form.Label>Filas</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  defaultValue={section.rows}
                                  disabled
                                  disabled
                                ></Form.Control>
                              </Form.Group>
                            </Col> */}
                              <Col>
                                <Form.Group
                                  as={Col}
                                  className="mb-3"
                                  controlid="name"
                                >
                                  <Form.Label>Precio</Form.Label>
                                  <Form.Control
                                    type="text"
                                    required
                                    defaultValue={section.price}
                                    disabled
                                    onChange={(e) =>
                                      updateSection(
                                        section,
                                        "price",
                                        e.target.value
                                      )
                                    }
                                  ></Form.Control>
                                </Form.Group>
                              </Col>
                            </Row>
                          );
                        })}

                        <Stack direction="horizontal" gap={10}>
                          <Button id="goBack" onClick={() => navigate(-1)}>
                            <BsArrowLeftCircle /> Atras
                          </Button>
                        </Stack>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default ViewEvent;
